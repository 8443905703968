import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect, useMemo, useState } from 'react';
import MainContent from 'components/MainContent';
import PageWrapper from 'components/PageWrapper';
import { useLocationState } from 'hooks/useLocationState';
import ObserverRecertificationImg from 'images/recertificationProductKey/recertProductKey.jpg';
import ObserverRecertificationMobileImg from 'images/recertificationProductKey/recertProductKeyMobile.jpg';
import ChooseCertificationType from 'components/RecertificationProductKey/ChooseCertificationType';
import ChooseLanguage from 'components/RecertificationProductKey/ChooseLanguage';
import {
  CertificationItem,
  ObserverRecertificationState
} from 'types/api/CertificationItemProductKey';
import useCurrentUser from 'hooks/useCurrentUser';
import useAsync from 'hooks/useAsync';
import { activateProductKeyWithTestTemplate } from 'actions/productKeys';
import { useNavigate } from 'react-router-dom';
import Routes from 'helpers/routes';
import ConfirmationModal from 'components/RecertificationProductKey/ConfirmationModal/ConfirmationModal';
import ConfirmationModalBody from 'components/RecertificationProductKey/ConfirmationModal/ConfirmationModalBody';
import redeemToken from 'images/recertificationProductKey/redeemToken.svg';
import { Loader } from 'semantic-ui-react';
import useQuery from 'helpers/useQuery';
import useProductKey from 'components/ActivateProductKey/useProductKey';
import PageLoader from 'components/PageLoader';

type TokenParams = {
  token?: string;
};

function ObserverRecertificationProductKey() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();
  const pKeyRequest = useProductKey();
  const query = useQuery();
  const [unifiedToken, setUnifiedToken] = useState(
    () => window.localStorage.getItem(`unified_token`) || null
  );
  const params: TokenParams = useMemo(() => {
    return {
      ...query
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const certifications = useLocationState<ObserverRecertificationState>();
  const asyncRequest = useAsync();
  const currentUser = useCurrentUser();
  const [formStep, setFormStep] = useState(0);
  const [language, setLanguage] = useState('');
  const [selectedCertification, setSelectedCertification] =
    useState<CertificationItem>({
      name: '',
      test_template_id: 0,
      role: '',
      age_level_group: '',
      group: '',
      version: '',
      type: 'recertification',
      language: '',
      eligible_for_transition: false,
      multiple_certifications: false
    });
  const data = {
    key: certifications?.key,
    user: {
      email: currentUser.email,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      already_in_okta: true
    }
  };

  useEffect(() => {
    if (!params.token && !unifiedToken) {
      return;
    }

    if (params.token) {
      window.localStorage.setItem(`unified_token`, params.token);
      setUnifiedToken(params.token);
      pKeyRequest.submit(params.token);
    } else if (unifiedToken) {
      pKeyRequest.submit(unifiedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextFormStep() {
    if (selectedCertification.test_template_id === 0) return;

    if (formStep === 0) {
      setFormStep(prev => prev + 1);
    } else {
      if (
        language !== 'English' &&
        language !== 'Spanish' &&
        language !== 'French'
      )
        return;
      setShowConfirmationModal(true);
    }

    return null;
  }

  function backFormStep() {
    if (formStep === 1) setFormStep(prev => prev - 1);

    return null;
  }

  function isFirstStep() {
    return formStep === 0;
  }

  function findCertification(certifications: CertificationItem[]) {
    return certifications.find(
      certification =>
        certification.language === language &&
        certification.name === selectedCertification.name
    );
  }

  function handleSubmit() {
    let certificationMatch;
    if (formStep === 1) {
      window.localStorage.removeItem(`unified_token`);
      if (selectedCertification.type === 'recertification') {
        certificationMatch = findCertification(certifications.recertification);
      } else {
        certificationMatch = findCertification(certifications.transition);
      }

      if (certificationMatch) {
        asyncRequest.run(
          activateProductKeyWithTestTemplate(
            certificationMatch.test_template_id,
            certificationMatch.role,
            data
          ),
          {
            onSuccess: ({ message }) => {
              setUnifiedToken(null);
              navigate(Routes.getPath('certifications'), {
                state: {
                  showRedeemTokenError: false,
                  showSuccess: true,
                  tokenType: 'test_template',
                  message
                }
              });
            },
            onError: () => {
              setUnifiedToken(null);
              navigate(Routes.getPath('certifications'), {
                state: {
                  showRedeemTokenError: true,
                  showSuccess: true,
                  tokenType: 'test_template'
                }
              });
            }
          }
        );
      } else {
        navigate(Routes.getPath('certifications'), {
          state: {
            showRedeemTokenError: true,
            showSuccess: true,
            tokenType: 'test_template'
          }
        });
      }
    }
  }

  function isButtonDisabled() {
    if (isFirstStep()) {
      return selectedCertification.test_template_id === 0;
    } else {
      return language === '' || !language;
    }
  }

  if (!certifications) return <PageLoader />;

  return (
    <MainContent maxWidth={1280}>
      <ConfirmationModal
        modalIcon={redeemToken}
        title={i18n.ft(messages.confirmationModal.title)}
        message={
          <ConfirmationModalBody
            selectedCertification={{ ...selectedCertification, language }}
          />
        }
        dismissButtonText={i18n.ft(messages.confirmationModal.cancel)}
        confirmButtonText={i18n.ft(messages.confirmationModal.create)}
        isOpen={showConfirmationModal}
        onConfirm={() => handleSubmit()}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        isLoading={asyncRequest.isLoading}
      />
      <PageWrapper borderStyle>
        <div className="grid lg:grid-cols-2 gap-4 md:grid-cols-1 mb-16">
          <div className="sm:hidden md:inline lg:block md:h-auto lg:h-100">
            <img
              src={ObserverRecertificationImg}
              alt="Observer Recertification"
              className="hidden md:h-48 md:w-full object-cover lg:h-auto lg:w-auto lg:block"
            />
            <img
              src={ObserverRecertificationMobileImg}
              alt="Observer Recertification"
              className="hidden md:h-48 md:w-full object-cover lg:h-auto lg:w-auto md:block lg:hidden"
            />
          </div>
          <div>
            <h1>{i18n.ft(messages.choose)}</h1>
            <div>
              {isFirstStep()
                ? i18n.ft(messages.certification)
                : i18n.ft(messages.language)}
            </div>
            {isFirstStep() ? (
              <ChooseCertificationType
                certifications={
                  unifiedToken
                    ? {
                        recertification: [],
                        transition: certifications.transition
                      }
                    : certifications
                }
                setSelectedCertification={setSelectedCertification}
                testTemplateId={selectedCertification.test_template_id}
                role={selectedCertification.role}
              />
            ) : null}

            {isFirstStep() ? null : (
              <ChooseLanguage
                certifications={certifications}
                selectedCertification={selectedCertification}
                updateCertificationLanguage={setLanguage}
              />
            )}

            <div className="rounded-b-sm bg-white py-2 mb-4">
              <div className="md:grid md:grid-cols-2 gap-1 px-5 md:gap-7 flex flex-col-reverse flex-nowrap h-full">
                <div className="col-span-2 md:col-span-1">
                  {isFirstStep() ? null : (
                    <button
                      className="rounded outline outline-gray-300 w-full my-2 py-2.5"
                      onClick={backFormStep}
                    >
                      {i18n.ft(messages.back)}
                    </button>
                  )}
                </div>
                <div className="col-span-2 md:col-span-1">
                  <button
                    className={`rounded w-full font-bold my-2 py-2.5 ${
                      isButtonDisabled()
                        ? 'bg-[#EAECF1] text-[#ADADAD]'
                        : 'bg-[#385E8E] text-white'
                    }`}
                    onClick={nextFormStep}
                    disabled={isButtonDisabled()}
                  >
                    {asyncRequest.isLoading ? (
                      <Loader active inline="centered" />
                    ) : (
                      i18n.ft(messages.next)
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </MainContent>
  );
}

export default ObserverRecertificationProductKey;

import { useCallback, useEffect, useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { RadioInput } from 'components/KnowledgeTest/Styled';
import {
  HRSeparator,
  SeparatorText,
  SeparatorWrapper,
  StyledPopup
} from './Styled';
import LampIcon from 'images/reliabilityTest/lampIcon.svg';
import WarningIcon from 'images/recertificationProductKey/warning.svg';
import CloseIcon from 'images/recertificationProductKey/close.svg';
import { CertificationItem } from 'types/api/CertificationItemProductKey';
import {
  formatCertification,
  getVersions,
  getAgeLevelGroup,
  sortByAgeLevelsOrder
} from './utils';
import { IconInfo } from 'components/icons';

interface ChooseCertificationTypeProps {
  certifications: {
    recertification: CertificationItem[];
    transition: CertificationItem[];
  };
  setSelectedCertification: (certification: any) => void;
  testTemplateId: number;
  role: string;
}

function ChooseCertificationType({
  certifications,
  setSelectedCertification,
  testTemplateId,
  role
}: ChooseCertificationTypeProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [uniqueRecertifications, setUniqueRecertifications] = useState<
    CertificationItem[]
  >([]);
  const [uniqueTransitions, setUniqueTransitions] = useState<
    CertificationItem[]
  >([]);
  const [recertificationData, setRecertificationData] = useState<
    CertificationItem[]
  >([]);
  const [transitionData, setTransitionData] = useState<CertificationItem[]>([]);

  function isThereOnlyOneRecertification(
    recertifications: CertificationItem[],
    transitions: CertificationItem[]
  ) {
    return recertifications.length === 1 && transitions.length === 0;
  }

  function isThereOnlyOneTransition(
    recertifications: CertificationItem[],
    transitions: CertificationItem[]
  ) {
    return transitions.length === 1 && recertifications.length === 0;
  }

  const selectCertificationByDefault = useCallback(
    (
      recertifications: CertificationItem[],
      transitions: CertificationItem[]
    ) => {
      let selectedCertification: CertificationItem[] = [];
      let certificationType = '';
      if (isThereOnlyOneRecertification(recertifications, transitions)) {
        certificationType = 'recertification';
        selectedCertification = recertifications;
      } else if (isThereOnlyOneTransition(recertifications, transitions)) {
        certificationType = 'transition';
        selectedCertification = transitions;
      }

      if (
        certificationType === 'recertification' ||
        certificationType === 'transition'
      ) {
        setSelectedCertification({
          name: selectedCertification[0].name,
          test_template_id: selectedCertification[0].test_template_id,
          age_level_group: getAgeLevelGroup(selectedCertification[0].name),
          group: selectedCertification[0].group,
          version: getVersions(selectedCertification[0].name),
          type: certificationType,
          multiple_certifications: selectedCertification[0].name.includes('and')
        });
      }
    },
    [setSelectedCertification]
  );

  useEffect(() => {
    const dedupRecertifications = certifications.recertification.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => t.group === value.group && t.name === value.name)
    );

    const dedupTransitions = certifications.transition.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => t.group === value.group && t.name === value.name)
    );

    selectCertificationByDefault(dedupRecertifications, dedupTransitions);
    setUniqueRecertifications(dedupRecertifications);
    setUniqueTransitions(dedupTransitions);
  }, [certifications, selectCertificationByDefault]);

  useEffect(() => {
    const recertificationsOnly = uniqueRecertifications.map(
      (item: CertificationItem) => ({
        ...item,
        version: getVersions(item.name),
        name: item.name,
        age_level_group: getAgeLevelGroup(item.name),
        multiple_certifications: item.name.includes('and')
      })
    );

    const recertificationsToOrder = [...recertificationsOnly];
    recertificationsToOrder.sort(sortByAgeLevelsOrder);
    setRecertificationData(recertificationsToOrder);
  }, [uniqueRecertifications]);

  useEffect(() => {
    const transitionsOnly = uniqueTransitions.map(
      (item: CertificationItem) => ({
        ...item,
        version: getVersions(item.name),
        name: item.name,
        age_level_group: getAgeLevelGroup(item.name),
        eligible_for_transition: item.eligible_for_transition,
        multiple_certifications: item.name.includes('and')
      })
    );

    const transitionsToOrder = [...transitionsOnly];
    transitionsToOrder.sort(sortByAgeLevelsOrder);
    setTransitionData(transitionsToOrder);
  }, [uniqueTransitions]);

  function handleOpen() {
    setIsPopupOpen(true);
  }

  function handleClose() {
    setIsPopupOpen(false);
  }

  return (
    <div className="rounded-t-sm bg-white p-4 my-4">
      <div className="my-4 flex items-center">
        <div className="text-xl font-bold mr-4">{i18n.ft(messages.title)}</div>
        <StyledPopup
          size="mini"
          on="click"
          open={isPopupOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          pinned
          content={
            <div className="grid grid-cols-7 gap-1">
              <div className="col-span-1">
                <img src={WarningIcon} alt="warning" />
              </div>
              <div className="col-span-6">
                {i18n.ft(messages.select)}
                <img
                  src={CloseIcon}
                  alt="close"
                  className="float-right cursor-pointer"
                  onClick={handleClose}
                  style={{
                    top: '-94px',
                    position: 'relative',
                    right: '-28px'
                  }}
                />
              </div>
            </div>
          }
          trigger={
            <div onClick={handleOpen}>
              <IconInfo />
            </div>
          }
        />
      </div>
      <SeparatorWrapper>
        <div className="my-4">
          {transitionData?.map((certification: CertificationItem) => (
            <div key={certification.test_template_id}>
              <div
                className={`grid grid-cols-9 py-1 mt-4 ${
                  certification.test_template_id === testTemplateId &&
                  certification.role === role
                    ? 'bg-[#EDEFF3] border-[#4A8264] border-solid border-2'
                    : 'bg-[#F3F4F6]'
                } rounded-lg cursor-pointer transition-colors hover:bg-[#EDEFF3] hover:shadow-lg`}
                onClick={() => {
                  setSelectedCertification({
                    name: certification.name,
                    test_template_id: certification.test_template_id,
                    role: certification.role,
                    age_level_group: certification.age_level_group,
                    group: certification.group,
                    version: certification.version,
                    type: 'transition',
                    multiple_certifications: certification.name.includes('and')
                  });
                }}
              >
                <div className="col-span-9 md:col-span-6 relative text-left pl-4">
                  <div className="grid grid-cols-6 gap-1 items-center">
                    <div className="col-span-1">
                      <RadioInput
                        id={`question_2${certification.test_template_id}`}
                        name={`question_2${certification.test_template_id}`}
                        data-answer-id={certification.test_template_id}
                        onChange={() => {
                          setSelectedCertification({
                            name: certification.name,
                            test_template_id: certification.test_template_id,
                            age_level_group: certification.age_level_group,
                            group: certification.group,
                            version: certification.version,
                            type: 'transition',
                            multiple_certifications:
                              certification.name.includes('and'),
                            role: certification.role
                          });
                        }}
                        checked={
                          certification.test_template_id === testTemplateId &&
                          certification.role === role
                        }
                        error={false}
                      />
                    </div>
                    <div className="col-span-5">
                      <label
                        htmlFor={`question_2${certification.test_template_id}`}
                        className="px-5 cursor-pointer"
                      >
                        <div className="text-lg">
                          {formatCertification(certification)}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-span-9 md:col-span-3 relative self-center">
                  <div className="grid grid-cols-6 gap-1 block md:hidden">
                    <div className="col-span-1"></div>
                    <div className="col-span-5">
                      <hr className="border-[#DEDEDE] w-44 border-b-2 rounded" />
                      <div className="text-left py-4 pl-3">
                        {i18n.ft(messages.transition)}
                      </div>
                    </div>
                  </div>
                  <div className="hidden text-center p-4 md:block">
                    {i18n.ft(messages.transition)}
                  </div>
                  <div className="absolute inset-y-0 left-0 w-px bg-[#DEDEDE]"></div>
                </div>
              </div>
              {certification.eligible_for_transition && (
                <div className="grid grid-cols-12 text-sm text-left px-1.5">
                  <div className="col-span-1">
                    <img src={LampIcon} alt="lamp" />
                  </div>
                  <div className="col-span-11">
                    <div>{i18n.ft(messages.note)}</div>
                    <a href="/">{i18n.ft(messages.learn)}</a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {recertificationData.length > 0 && transitionData.length > 0 ? (
          <div className="my-4 py-4">
            <HRSeparator />
            <SeparatorText>{i18n.ft(messages.or)}</SeparatorText>
          </div>
        ) : null}

        <div className="my-4">
          {recertificationData?.map((certification: CertificationItem) => (
            <div key={certification.test_template_id}>
              <div
                className={`grid grid-cols-9 py-1 mt-4 ${
                  certification.test_template_id === testTemplateId &&
                  certification.role === role
                    ? 'bg-[#EDEFF3] border-[#4A8264] border-solid border-2'
                    : 'bg-[#F3F4F6]'
                } rounded-lg cursor-pointer transition-colors hover:bg-[#EDEFF3] hover:shadow-lg`}
                onClick={() => {
                  setSelectedCertification({
                    name: certification.name,
                    test_template_id: certification.test_template_id,
                    role: certification.role,
                    age_level_group: certification.age_level_group,
                    group: certification.group,
                    version: certification.version,
                    type: 'recertification',
                    multiple_certifications: certification.name.includes('and')
                  });
                }}
              >
                <div className="col-span-9 md:col-span-6 relative text-left pl-4">
                  <div className="grid grid-cols-6 gap-1 items-center">
                    <div className="col-span-1">
                      <RadioInput
                        id={`question_${certification.test_template_id}`}
                        name={`question_${certification.test_template_id}`}
                        data-answer-id={certification.test_template_id}
                        onChange={() => {
                          setSelectedCertification({
                            name: certification.name,
                            test_template_id: certification.test_template_id,
                            age_level_group: certification.age_level_group,
                            group: certification.group,
                            version: certification.version,
                            type: 'recertification',
                            multiple_certifications:
                              certification.name.includes('and'),
                            role: certification.role
                          });
                        }}
                        checked={
                          certification.test_template_id === testTemplateId &&
                          certification.role === role
                        }
                        error={false}
                      />
                    </div>
                    <div className="col-span-5">
                      <label
                        htmlFor={`question_${certification.test_template_id}`}
                        className="px-5 cursor-pointer"
                      >
                        <div className="text-lg">
                          {formatCertification(certification)}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-span-9 md:col-span-3 relative self-center">
                  <div className="grid grid-cols-6 gap-1 block md:hidden">
                    <div className="col-span-1"></div>
                    <div className="col-span-5">
                      <hr className="border-[#DEDEDE] w-44 border-b-2 rounded" />
                      <div className="text-left py-4 pl-3">
                        {i18n.ft(messages.recertify)}
                      </div>
                    </div>
                  </div>
                  <div className="hidden text-center py-4 md:block">
                    {i18n.ft(messages.recertify)}
                  </div>
                  <div className="absolute inset-y-0 left-0 w-px bg-[#DEDEDE]"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SeparatorWrapper>

      <div className="flex justify-center">
        <p>{i18n.ft(messages.notListedObservation)}</p>
      </div>
    </div>
  );
}

export default ChooseCertificationType;

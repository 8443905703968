const PATH = 'fe.pages.training_history';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Professional Development History'
  }
};

export default messages;

import i18n from 'helpers/i18n';
import messages from './messages';
import { HierarchyNode } from './HierarchyNode';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { createHierarchyOptions } from 'components/Organization/Hierarchy/utils';
import { HierarchySearch } from 'components/Organization/Hierarchy/HierarchySearch';

import {
  NodeList,
  useHierarchy,
  HierarchyProvider
} from 'components/Organization/HierarchyBuilder';

function Hierarchy() {
  const navigate = useNavigate();
  const { accountId, nodes, nodesMap, setOpenNodeIds } = useHierarchy();

  const handleNodeSelect = (node: any) => {
    if (!node) {
      return;
    }

    navigate(`/organization/hierarchy/${node.id}`);
    setOpenNodeIds(node.treeIds);
  };

  return (
    <div className="my-8 relative">
      <div className="bg-[#0A9CCA] -mx-6 xl:-mx-14 left-0 right-0 h-52 rounded-b-2xl" />
      <div className="-mt-44 bg-white rounded-2xl">
        <div className="flex flex-col">
          <div className="p-6 pb-4 flex items-center justify-between">
            <h2 className="font-sans font-bold text-3xl">
              {i18n.ft(messages.header)}
            </h2>

            <div className="flex gap-4">
              <Link to="/organization/edit-hierarchy">
                <PrimaryButton>{i18n.ft(messages.editHierarchy)}</PrimaryButton>
              </Link>

              <a
                download
                href={`/api/v2/ca/accounts/${accountId}/hierarchy_mapping.csv`}
              >
                <PrimaryButton>
                  {i18n.ft(messages.downloadHierarchy)}
                </PrimaryButton>
              </a>
            </div>
          </div>

          <hr className="border-t border-[#D7DFE8]" />

          <div className="flex gap-6">
            <div className="basis-[460px] min-h-80 border-r border-charcoal-100">
              <div className="p-4 bg-charcoal-25 border-b border-charcoal-100">
                <HierarchySearch
                  nodes={createHierarchyOptions(nodes, nodesMap)}
                  onNodeSelect={handleNodeSelect}
                />
              </div>
              <NodeList NodeComponent={HierarchyNode} />
            </div>

            <div className="p-10 pr-16 flex-1">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HierarchyController() {
  return (
    <HierarchyProvider area="ManageOrganization">
      <Hierarchy />
    </HierarchyProvider>
  );
}

export default HierarchyController;

const PATH = 'fe.pages.learning_resources.detail_page';

const messages = {
  time: {
    key: `${PATH}.time`,
    defaultValue: 'Estimated Time:'
  },
  dimensions: {
    key: `${PATH}.dimensions`,
    defaultValue: 'Dimensions:'
  },
  instructions: {
    key: `${PATH}.instructions`,
    defaultValue: 'Instructions:'
  },
  indicators: {
    key: `${PATH}.indicators`,
    defaultValue: 'Indicators:'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue: 'Resource Description:'
  },
  goals: {
    key: `${PATH}.goals`,
    defaultValue: 'Learning Goals:'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  }
};

export default messages;

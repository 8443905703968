import i18n from 'helpers/i18n';
import messages from './messages';
import Title from 'design-system/Title';
import { TrainingList } from 'components/TrainingHistory/TrainingList';
import { TrainingSummary } from 'components/TrainingHistory/TrainingSummary';

function TrainingHistoryPage() {
  return (
    <main className="max-w-7xl mx-auto font-sans text-black">
      <Title>{i18n.ft(messages.title)}</Title>

      <div className="flex flex-col gap-16">
        <TrainingSummary />
        <TrainingList />
      </div>
    </main>
  );
}

export default TrainingHistoryPage;

import { sortByName } from './utils';
import { NodeProvider } from './NodeProvider';
import { useHierarchy } from './useHierarchy';

interface NodeListProps {
  depth?: number;
  NodeComponent: React.ComponentType<any>;
}

export function NodeList({ depth = 1, NodeComponent }: NodeListProps) {
  const { accountId, nodes } = useHierarchy();

  return (
    <div className="font-sans text-black">
      {sortByName(nodes).map(node => {
        return (
          <NodeProvider
            key={node.id}
            depth={depth}
            node={node}
            as={NodeComponent}
            accountId={accountId}
          >
            <NodeComponent />
          </NodeProvider>
        );
      })}
    </div>
  );
}

import i18n from 'helpers/i18n';
import messages from './messages';
import { useState, useEffect, useCallback } from 'react';
import ResourcesSection from '../ResourcesSection';
import useCurrentUser from 'hooks/useCurrentUser';
import DownArrow from 'images/arrow_drop_down.svg';
import QuestionHelp from 'images/questionHelp.svg';
import { Popup } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import SearchResults from '../SearchResults';

export const SECTION = {
  RECOMMENDED: 'recommended',
  COMPLETED: 'completed',
  CLASSROOM: 'based_on_classroom_score'
};

interface DropdownMenuProps {
  query: string;
  setQuery: (query: string) => void;
  selectedEducator: any;
  selectedAgeLevel: string;
}

const DropdownMenu = ({
  query,
  setQuery,
  selectedEducator,
  selectedAgeLevel
}: DropdownMenuProps) => {
  const currentUser = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [sectionSelected, setSectionSelected] = useState('get_started');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isOpen && !target.closest('.dropdown')) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const SelectedSection = useCallback(() => {
    setQuery('');
    switch (sectionSelected) {
      case SECTION.RECOMMENDED:
        return (
          <ResourcesSection
            action={'recommendedResources'}
            educatorGuid={selectedEducator.guid}
            educatorName={selectedEducator.name}
            showTitle={false}
          />
        );
      case SECTION.COMPLETED:
        return (
          <ResourcesSection
            action={'completedRecommended'}
            educatorId={selectedEducator.id}
            educatorName={selectedEducator.name}
            showTitle={false}
          />
        );
      case SECTION.CLASSROOM:
        return (
          <ResourcesSection
            action={'classroomScores'}
            educatorId={selectedEducator.id}
            educatorName={selectedEducator.name}
            ageLevel={selectedAgeLevel}
            showTitle={false}
          />
        );
      default:
        setSectionSelected(SECTION.RECOMMENDED);
        return (
          <ResourcesSection
            action={'recommendedResources'}
            educatorGuid={selectedEducator.guid}
            educatorName={selectedEducator.name}
            showTitle={false}
          />
        );
    }
  }, [
    sectionSelected,
    selectedAgeLevel,
    selectedEducator.guid,
    selectedEducator.id,
    selectedEducator.name,
    setQuery
  ]);

  const getSectionTitle = useCallback(() => {
    if (query) {
      return i18n.ft(messages.searchText, { query });
    }

    switch (sectionSelected) {
      case SECTION.RECOMMENDED:
        return i18n.ft(messages.recommended, { name: selectedEducator.name });
      case SECTION.COMPLETED:
        return i18n.ft(messages.completed, { name: selectedEducator.name });
      case SECTION.CLASSROOM:
        return i18n.ft(messages.classroom, { name: selectedEducator.name });
      default:
        return i18n.ft(messages.recommended, { name: selectedEducator.name });
    }
  }, [query, sectionSelected, selectedEducator.name]);

  function handleOnClick(key: string) {
    setQuery('');
    setIsOpen(false);
    setSectionSelected(key);
  }

  function hasLearningResourcesSubscription() {
    return (
      currentUser.roles.includes('lr_coach') ||
      currentUser.roles.includes('lr_educator')
    );
  }

  // NOTE: Users that haven't an learning resource subscription
  //       should be redirected to the home page
  if (!hasLearningResourcesSubscription()) {
    return <Navigate to={RouteHelpers.getPath('app')} replace />;
  }

  return (
    <>
      <div className="dropdown relative inline-block text-left">
        <button
          onClick={toggleMenu}
          className="inline-flex pr-4 py-2 hover:bg-gray-50 focus:outline-none"
        >
          <h2 className="text-black">{getSectionTitle()}</h2>
          <span className="ml-2 self-center">
            <img src={DownArrow} alt="" />
          </span>
        </button>
        <span className="ml-2 self-center inline-block">
          <Popup
            content={
              <span>
                <p className="text-sm">{i18n.ft(messages.help)}</p>
              </span>
            }
            trigger={<img src={QuestionHelp} alt="" />}
            position="bottom left"
            flowing
          />
        </span>

        {isOpen && (
          <div className="min-w-[341px] absolute mt-2 rounded-md shadow-lg bg-white border-[#0A9CCA] border-2 ring-1 ring-black ring-opacity-5 z-10">
            <ul
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.RECOMMENDED ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.RECOMMENDED);
                }}
              >
                {i18n.ft(messages.recommended, { name: selectedEducator.name })}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.recommendDescription, {
                    name: selectedEducator.name
                  })}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.COMPLETED ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.COMPLETED);
                }}
              >
                {i18n.ft(messages.completed, { name: selectedEducator.name })}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.completedDescription, {
                    name: selectedEducator.name
                  })}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.CLASSROOM ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.CLASSROOM);
                }}
              >
                {i18n.ft(messages.classroom, { name: selectedEducator.name })}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.classroomDescription, {
                    name: selectedEducator.name
                  })}
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
      {query === '' ? (
        <SelectedSection />
      ) : (
        <SearchResults
          query={query}
          searchTitle={i18n.ft(messages.searchText, { query })}
        />
      )}
    </>
  );
};

export default DropdownMenu;

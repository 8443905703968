import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { TrainingHistorySummary } from 'types/api/TrainingHistory';
import { useGetTrainingHistorySummary } from 'actions/trainingHistory';

interface TrainingSummaryViewProps {
  summary: TrainingHistorySummary;
}

function TrainingSummaryView({ summary }: TrainingSummaryViewProps) {
  return (
    <section>
      <h2 className="font-sans font-bold text-white text-2xl bg-blue-500 px-6 py-3 rounded-t-xl">
        {i18n.ft(messages.pdAccomplishments)}
      </h2>

      <div className="mt-6 flex gap-6">
        <div className="px-6 py-4 bg-white shadow-sm rounded-lg flex-1 flex items-center justify-between">
          <span className="font-sans text-xl font-bold">
            {i18n.ft(messages.ceus)}
          </span>

          <span className="font-sans text-xl font-bold">
            {summary.total_ceus}
          </span>
        </div>

        <div className="px-6 py-4 bg-white shadow-sm rounded-lg flex-1 flex items-center justify-between">
          <span className="font-sans text-xl font-bold">
            {i18n.ft(messages.hours)}
          </span>

          <span className="font-sans text-xl font-bold">
            {summary.total_hours}
          </span>
        </div>
      </div>
    </section>
  );
}

export function TrainingSummary() {
  const user = useCurrentUser();
  const { data } = useGetTrainingHistorySummary(user.id);
  const summary = data || { total_ceus: '0', total_hours: '0' };

  return <TrainingSummaryView summary={summary} />;
}

import { useCallback, useEffect, useMemo } from 'react';
import { createUserEngagementEvent } from 'actions/userEngagementEvents';
import {
  ENGAGEMENT_OBJECT,
  ENGAGEMENT_VERB as VERB
} from 'types/api/UserEngagementEvent';

const kebabCase = (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

interface UseUserEngagementEventProps {
  objectId: number | string | undefined;
  objectType: Extract<ENGAGEMENT_OBJECT, 'LearningResourceRecord'>;
  ready?: boolean;
  userId: number;
  verb: keyof VERB;
}

/**
 * @function useUserEngagementEvent a hook to conditionally transmit engagement event data
 * @description writes a session storage item noting an engagement event from the session
 * @param userId the user from the session
 * @param objectId the engagement target object
 * @param objectType the engagement target object's class
 * @param verb the controlled term descibing the kind of engagement
 * @param ready optional param to delay execution (set to true when ready to trigger)
 */
function useUserEngagementEvent({
  objectId,
  objectType,
  ready,
  userId,
  verb
}: UseUserEngagementEventProps) {
  const tokenKey = kebabCase(`user-${verb}-${objectType}-${objectId}`);
  const tokenValue = useMemo(() => {
    return sessionStorage.getItem(tokenKey);
  }, [tokenKey]);

  const writeToSession = useCallback(() => {
    if (verb === 'COMPLETES') return;
    const now = new Date().toUTCString();
    sessionStorage.setItem(tokenKey, now);
  }, [verb, tokenKey]);

  useEffect(() => {
    if (ready === false) return;
    if (objectId === undefined) return;
    if (!tokenValue) {
      createUserEngagementEvent({
        subjectId: userId,
        verb,
        objectId,
        objectType
      })
        .then(() => {
          writeToSession();
        })
        .catch((err: unknown) => {
          console.error(JSON.stringify(err));
        });
    }
  }, [
    objectId,
    objectType,
    ready,
    tokenValue,
    userId,
    verb,
    writeToSession,
    tokenKey
  ]);

  return [tokenKey, tokenValue];
}

export default useUserEngagementEvent;

import { toast } from 'react-toastify';

interface ToastProps {
  message: string;
  type: 'success' | 'error';
}

function Toast({ message, type }: ToastProps) {
  const iconClass =
    type === 'success' ? 'fa-circle-check' : 'fa-circle-x text-error';
  const textClass = type === 'success' ? 'text-white' : 'text-dark';

  return (
    <div
      className={`font-sans font-semibold text-base flex items-center gap-2.5 ${textClass}`}
    >
      <i className={`fa-solid ${iconClass}`} />
      <span>{message}</span>
    </div>
  );
}

export function toastSuccess(props: Omit<ToastProps, 'type'>) {
  toast(<Toast {...props} type="success" />, {
    closeButton: false,
    className: '!bg-confirmation !min-h-0 !p-4 !rounded-[10px] !border-0',
    bodyClassName: '!p-0'
  });
}

export function toastError(props: Omit<ToastProps, 'type'>) {
  toast(<Toast {...props} type="error" />, {
    closeButton: false,
    className: '!bg-red-100 !min-h-0 !p-4 !rounded-[10px] !border-0',
    bodyClassName: '!p-0'
  });
}

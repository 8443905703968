const PATH = 'fe.components.organization.delete_node_modal';

const messages = {
  remove: {
    key: `${PATH}.remove`,
    defaultValue: 'Remove {{nodeTypeName}}'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'Are you sure you want to remove this {{nodeTypeName}} from your hierarchy?'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'No, Cancel'
  }
};

export default messages;

import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import MainContent from 'components/MainContent';
import { Navigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { getUserToken, respondTokenInvite } from 'actions/userTokens';
import myCoachConnectIcon from 'images/dashboard/myCoachConnect.svg';
import envScalesIcon from 'images/dashboard/envScales.svg';
import learningResourcesIcon from 'images/dashboard/learningResources.svg';

import {
  Wrapper,
  ServiceWrapper,
  Service,
  Header,
  SubHeader,
  Description,
  DetailsWrapper,
  DetailsHeader,
  Divider,
  Item,
  IconWrapper,
  ItemLabel,
  ButtonWrapper
} from './Styled';

type URLParams = {
  token: string;
};

function View() {
  const acceptRequest = useAsync();
  const { token } = useRouteParams<URLParams>();
  const { run, data: userToken, isPending, isError } = useAsync();

  useEffect(() => {
    run(getUserToken(token));
  }, [run, token]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  if (isError || !userToken.usable || acceptRequest.isSuccess) {
    return <Navigate replace to="/" />;
  }

  function handleAccept() {
    acceptRequest.run(respondTokenInvite(userToken.token, 'accept'));
  }

  function icon() {
    const productIcons = {
      data_collection: { icon: envScalesIcon, alt: 'Data Collection' },
      my_coach_connect: { icon: myCoachConnectIcon, alt: 'myCoach Connect' },
      learning_resources: {
        icon: learningResourcesIcon,
        alt: 'Learning Resources 2.0'
      }
    };

    const productName = userToken.subscription
      .product_name as keyof typeof productIcons;
    return productIcons[productName] || {};
  }

  return (
    <MainContent maxWidth={1220}>
      <Wrapper>
        <ServiceWrapper>
          <Service src={icon().icon} alt={icon().alt} />
        </ServiceWrapper>
        <Header>{i18n.ft(messages.header)}</Header>
        <SubHeader>
          {i18n.ft(messages.invited, {
            account: userToken.subscription.owner_name,
            product: userToken.subscription.product_display_name
          })}
        </SubHeader>
        <Description>{i18n.ft(messages.lookFor)}</Description>
        <Divider className="mx-auto" />
        <div>
          <DetailsWrapper>
            <DetailsHeader>{i18n.ft(messages.accessDesc)}</DetailsHeader>

            <Item>
              <IconWrapper>
                <Icon fitted name="user" />
              </IconWrapper>
              <ItemLabel>{i18n.ft(messages.profile)}</ItemLabel>
            </Item>
            <Item key={userToken.subscription.product_display_name}>
              <IconWrapper>
                <Icon fitted name="signal" />
              </IconWrapper>
              <ItemLabel>
                {i18n.ft(messages.productActivity, {
                  product: userToken.subscription.product_display_name
                })}
              </ItemLabel>
            </Item>
          </DetailsWrapper>
        </div>
        <ButtonWrapper>
          <Button
            fluid
            size="large"
            color="green"
            onClick={handleAccept}
            loading={acceptRequest.isLoading}
            disabled={acceptRequest.isLoading}
            content={i18n.ft(messages.accept)}
          />
        </ButtonWrapper>
      </Wrapper>
    </MainContent>
  );
}

export default View;

import { clsx } from 'clsx';
import { useNode } from './useNode';

export function NodeName() {
  const { depth, node } = useNode();
  const classes = clsx('text-base py-2', depth === 1 && 'font-semibold');

  return node.type === 'Classroom' ? (
    <div className="flex flex-col">
      <span className="text-xs font-semibold">CLASSROOM</span>
      <span className="text-base">{node.name}</span>
    </div>
  ) : (
    <span className={classes}>{node.name}</span>
  );
}

const PATH = 'fe.components.learning_resources.resources_section';

const messages = {
  completedRecommended: {
    key: `${PATH}.completed_recommended`,
    defaultValue: "{{educator}}'s Completed Resources"
  },
  recommendations: {
    key: `${PATH}.recommendations`,
    defaultValue: 'Previously recommended to {{educator}}'
  },
  classroomScores: {
    key: `${PATH}.classroom_scores`,
    defaultValue: 'Based on Classroom Scores for {{educator}}'
  },
  myResources: {
    key: `${PATH}.my_resources`,
    defaultValue: 'My Resources'
  },
  recommendedTo: {
    key: `${PATH}.recommended_to`,
    defaultValue: 'Recommended to You'
  },
  gettingStarted: {
    key: `${PATH}.getting_started`,
    defaultValue: 'Getting Started with Learning Resources'
  },
  viewingHistory: {
    key: `${PATH}.viewing_history`,
    defaultValue: 'Based on My Viewing History'
  },
  important: {
    key: `${PATH}.important`,
    defaultValue: 'Marked as Important'
  },
  lastViewed: {
    key: `${PATH}.last_viewed`,
    defaultValue: 'Last Viewed'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No results found'
  },
  loadMore: {
    key: `${PATH}.load_more`,
    defaultValue: 'Load More'
  }
};

export default messages;

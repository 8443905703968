const PATH = 'fe.components.measure.home.assessment_card';

const messages = {
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center:'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom:'
  },
  jobId: {
    key: `${PATH}.job_id`,
    defaultValue: 'Job ID:'
  },
  teacher: {
    key: `${PATH}.teacher`,
    defaultValue: 'Teacher(s):'
  },
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'Observer:'
  },
  classAssessment: {
    key: `${PATH}.class_assessment`,
    defaultValue: 'CLASS Assessment:'
  },
  score: {
    key: `${PATH}.score`,
    defaultValue: 'Score:'
  },
  linkText: {
    key: `${PATH}.link_text`,
    defaultValue: 'Observation {{date}}'
  },
  moreTeachers: {
    key: `${PATH}.more_teachers`,
    defaultValue: 'and more'
  },
  pdf: {
    key: `${PATH}.pdf`,
    defaultValue: 'Download PDF'
  },
  csv: {
    key: `${PATH}.csv`,
    defaultValue: 'Download CSV'
  },
  class_report: {
    key: `${PATH}.class_report`,
    defaultValue: 'CLASS Report'
  },
  environment_report: {
    key: `${PATH}.environment_report`,
    defaultValue: 'Environment Report'
  },
  assessment: {
    key: `${PATH}.assessment`,
    defaultValue: 'Assessment:'
  },
  codePrimary: {
    key: `${PATH}.code_primary`,
    defaultValue: 'Double Code: Primary'
  },
  codeSecondary: {
    key: `${PATH}.code_secondary`,
    defaultValue: 'Double Code: Secondary'
  },
  environments: {
    key: `${PATH}.environments`,
    defaultValue: 'Environments'
  },
  class1: {
    key: `${PATH}.class_1`,
    defaultValue: 'CLASS 2008'
  },
  class2: {
    key: `${PATH}.class_2`,
    defaultValue: 'CLASS 2nd Edition'
  },
  inf: {
    key: `${PATH}.inf`,
    defaultValue: 'Infant'
  },
  todd: {
    key: `${PATH}.todd`,
    defaultValue: 'Toddler'
  },
  'pre-k': {
    key: `${PATH}.pre-k`,
    defaultValue: 'Pre-K'
  },
  pk3rd: {
    key: `${PATH}.pk3rd`,
    defaultValue: 'Pre-K–3rd'
  },
  pkk: {
    key: `${PATH}.pkk`,
    defaultValue: 'Pre-K/K'
  },
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Review'
  }
};

export default messages;

import i18n from 'helpers/i18n';
import { Tag } from 'types/api/Tag';
import messages from './messages';

function DimensionTagLabel({ tag }: { tag?: Tag }) {
  if (!tag) {
    return <></>;
  }

  return (
    <div className="mt-4">
      <span className="font-bold text-black">
        {i18n.ft(messages.dimensions)}
      </span>
      <span className="ml-4 p-2 border rounded border-neutral-700 text-sm font-bold text-[#3C3F42]">
        {tag.description}
      </span>
    </div>
  );
}

export default DimensionTagLabel;

import axios from 'axios';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE
} from 'pages/LearningResources/utils';

export function getMyResources(
  perPage: number = DEFAULT_PER_PAGE,
  currentPage: number = DEFAULT_CURRENT_PAGE
) {
  return axios.get(
    `/api/v2/learning_resources/my_resources?per_page=${perPage}&page=${currentPage}`
  );
}

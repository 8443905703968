const PATH = 'fe.pages.organization.hierarchy';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Hierarchy'
  },
  downloadHierarchy: {
    key: `${PATH}.download_hierarchy`,
    defaultValue: 'Download Hierarchy'
  },
  editHierarchy: {
    key: `${PATH}.edit_hierarchy`,
    defaultValue: 'Edit Hierarchy'
  }
};

export default messages;

const PATH = 'fe.components.organization.edit_hierarchy.editable_node';

const messages = {
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  editName: {
    key: `${PATH}.edit_name`,
    defaultValue: 'Edit Name'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom'
  },
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center'
  },
  addClassroom: {
    key: `${PATH}.add_classroom`,
    defaultValue: 'Add Classroom'
  },
  addCenter: {
    key: `${PATH}.add_center`,
    defaultValue: 'Add Center'
  },
  removeClassroom: {
    key: `${PATH}.remove_classroom`,
    defaultValue: 'Remove Classroom'
  },
  removeCenter: {
    key: `${PATH}.remove_center`,
    defaultValue: 'Remove Center'
  },
  nodeAdded: {
    key: `${PATH}.node_added`,
    defaultValue: '{{nodeName}} was successfully added'
  },
  nodeEdited: {
    key: `${PATH}.node_edit`,
    defaultValue: '{{nodeName}} was successfully edited'
  },
  nodeError: {
    key: `${PATH}.node_error`,
    defaultValue: '{{nodeName}} could not be added'
  },
  nodeEditError: {
    key: `${PATH}.node_edit_error`,
    defaultValue: '{{nodeName}} could not be edited'
  },
  nodeRemoved: {
    key: `${PATH}.node_removed`,
    defaultValue: '{{nodeName}} was removed from your account'
  }
};

export default messages;

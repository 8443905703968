import * as R from 'ramda';
import moment from 'moment';
import qs from 'query-string';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import DatePicker from 'components/DatePicker';
import debounce from 'debounce';
import { Link, useLocation } from 'react-router-dom';
import { AgeLevelGroup } from 'types/api/AgeLevelGroup';
import { getAgeLevelGroups } from 'actions/ageLevelGroups';
import { useCurrentAccountByArea } from 'pages/Reports/Observation';
import AgeLevelTag from 'components/Certifications/AgeLevelTag';
import { StatusTag } from 'components/Certifications/StatusTag';
import { useEffect, useCallback, useState, useMemo } from 'react';
import ObserversTable from 'components/Certifications/ObserversTable';
import { getTableColumns, formatDate, formatAgeLevels } from './utils';
import { ClassVersionTag } from 'components/Certifications/ClassVersionTag';
import HierarchyDropdown from 'components/Reports/Observation/HierarchyDropdown';
import { StatusCard } from 'components/Certifications/ObserversByAgeLevel/CertificationsByStatus/StatusCard';
import { clsx } from 'clsx';

import {
  UserCertification,
  CERTIFICATION_STATUS
} from 'types/api/CertificationDashboard';

import {
  getCertificationHierarchy,
  useGetAccountCertifiedUsers,
  useGetCertificationsByStatus
} from 'actions/certificationDashboard';

import {
  Checkbox,
  Dropdown,
  Icon,
  Loader,
  Select,
  Input,
  DropdownProps
} from 'semantic-ui-react';

type HierarchyData = {
  label: string;
  value: number;
  children: HierarchyData[];
};

type Node = {
  id: number;
  name: string;
  type: string;
  children: Node[];
};

function Observers() {
  let { state } = useLocation();
  const currentAccount = useCurrentAccountByArea('CertificationsDashboard');
  const [ageLevelGroups, setAgeLevelGroups] = useState<AgeLevelGroup[]>([]);
  const [ageLevel, setAgeLevel] = useState<number>(state?.ageLevelId || 0);
  const [classVersion, setClassVersion] = useState<string | null>(
    state?.classVersion || null
  );
  const [nodesId, setNodesId] = useState<number[]>([]);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');
  const { run: hierarchyRun, ...hierarchyReq } = useAsync();

  const { data: usersData, isPending } = useGetAccountCertifiedUsers({
    node_ids: nodesId,
    account_id: currentAccount?.id,
    age_level_group_id: ageLevel || undefined,
    class_version: classVersion || undefined,
    date: moment(date).format('YYYY-MM-DD'),
    status: status === 'all' ? null : status,
    query: search
  });

  const { data } = useGetCertificationsByStatus({
    node_ids: nodesId,
    account_id: currentAccount?.id,
    age_level_group_id: ageLevel || undefined,
    class_version: classVersion || undefined,
    date: moment(date).format('YYYY-MM-DD')
  });

  const customColumns = [
    { key: 'email', name: i18n.ft(messages.email), checked: true },
    { key: 'hierarchy', name: i18n.ft(messages.hierarchy), checked: true },
    { key: 'status', name: i18n.ft(messages.certStatus), checked: true },
    {
      key: 'expiration',
      name: i18n.ft(messages.certExpiration),
      checked: true
    },
    {
      key: 'training_date',
      name: i18n.ft(messages.trainingDate),
      checked: true
    },
    {
      key: 'first_test_start_date',
      name: i18n.ft(messages.testStartDate),
      checked: true
    }
  ];
  const [columnState, setColumnState] = useState(customColumns);

  function fetchAgeLevelGroups() {
    const params = { certification_group_name: 'Observer' };
    getAgeLevelGroups(params).then(({ data }) => {
      setAgeLevelGroups(data.age_level_groups);
    });
  }

  useEffect(fetchAgeLevelGroups, []);

  useEffect(() => {
    hierarchyRun(getCertificationHierarchy());
  }, [hierarchyRun, currentAccount?.id]);

  const findHierarchyNames = useCallback(
    (data: any[], ids: number[], found: string[]) => {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        if (ids?.includes(record.id)) {
          found.push(record.name);
        }
        if (ids.length === found.length) {
          return found;
        }
        if (record.children) {
          findHierarchyNames(record.children, ids, found);
        } else {
          return found;
        }
      }
    },
    []
  );

  function buildHierarchy(nodes: Node[]): HierarchyData[] {
    return nodes.reduce((result: HierarchyData[], node: Node) => {
      if (node.type !== 'Classroom') {
        const hierarchyNode = {
          label: node.name,
          value: node.id,
          children: buildHierarchy(node.children)
        };
        result.push(hierarchyNode);
      }
      return result;
    }, []);
  }

  /**
   * Need to use `useCallback` and `useMemo` because of a bug with react-dropdown-tree-select
   * that would cause the entire list of selected options to be lost if any of the props change.
   */
  const hierarchy = useMemo(() => {
    if (!hierarchyReq.isSuccess) return [];

    const firstNode = hierarchyReq.data.hierarchy[0];
    if (firstNode && firstNode.type === 'Account' && firstNode.children) {
      return buildHierarchy(firstNode.children);
    } else {
      return buildHierarchy(hierarchyReq.data.hierarchy);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hierarchyReq.isSuccess, hierarchyReq.data]);

  const handleChange = useCallback(
    (_: any, tags: any) => {
      const nodeIds = tags.map((t: any) => Number(t.value));
      setNodesId(nodeIds);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  function findHierarchy(nodes: any[] = []) {
    if (hierarchyReq.isSuccess && hierarchyReq.data) {
      const found: string[] = [];
      const list =
        hierarchyReq.data.hierarchy[0].type === 'Account'
          ? hierarchyReq.data.hierarchy[0].children
          : hierarchyReq.data.hierarchy;
      return findHierarchyNames(list, nodes, found);
    }
    return [];
  }

  const isFutureDate = moment(date).isAfter(moment());

  const handleSearch = (e: any, { value }: any) => {
    if (value.length > 0 && value.length < 3) {
      return;
    }
    setSearch(value);
  };

  const extractUniqueAgeLevels = (
    certifications: UserCertification[],
    classVersion: '2008' | '2nd Edition'
  ): string[] => {
    return R.uniq(
      R.flatten(
        certifications
          .filter(c => c.class_version === classVersion)
          .map(c => c.age_levels)
      )
    );
  };

  function organizeData() {
    const data: any[] = [];

    usersData?.account_users.forEach(user => {
      let nodesNames: string[] | undefined;
      let ageLevels2008: string[] = [];
      let ageLevels2ndEdition: string[] = [];

      if (user.certifications.length > 0) {
        ageLevels2008 = extractUniqueAgeLevels(user.certifications, '2008');
        ageLevels2ndEdition = extractUniqueAgeLevels(
          user.certifications,
          '2nd Edition'
        );
      }

      if (user.node_names.length > 0) {
        nodesNames = user.node_names;
      }

      const lastCert = R.last(user.certifications);
      const certStatus: CERTIFICATION_STATUS = lastCert
        ? lastCert.status
        : 'seeking_initial';

      if (isFutureDate && certStatus === 'seeking_initial') {
        return;
      }

      data.push({
        name: user.user.name,
        email: user.user.email,
        hierarchy: nodesNames ? nodesNames.join(', ') : '',
        class_2008: formatAgeLevels(ageLevels2008),
        class_2nd_edition: formatAgeLevels(ageLevels2ndEdition),
        expiration: formatDate(lastCert?.expiration),
        training_date: formatDate(lastCert?.training_end_date),
        first_test_start_date: formatDate(lastCert?.first_test_start_date),
        status: <StatusTag status={certStatus} />
      });
    });

    return data;
  }

  function onChangeColumns(event: any, key: string) {
    event.stopPropagation();
    const newColumns = columnState.map(column => {
      if (column.key === key) {
        column.checked = !column.checked;
      }
      return column;
    });
    setColumnState(newColumns);
  }

  const columns = getTableColumns(Boolean(ageLevel), columnState);

  function getAgeLevel(id: number) {
    const ageLevelGroup = ageLevelGroups.find(group => group.id === id);
    const ageLevel = {
      name: ageLevelGroup?.alt_name
        ? ageLevelGroup.alt_name
        : ageLevelGroup?.name || i18n.ft(messages.allAgeLevels),
      code: ageLevelGroup?.code || 'all'
    };
    return ageLevel;
  }

  const ageLevelGroupOptions = [
    { text: i18n.ft(messages.allAgeLevels), value: 0 },
    ...ageLevelGroups.map(group => {
      const name = group.alt_name ? group.alt_name : group.name;

      const uniqId = `${group.id}-${group.class_version}`;

      const option = {
        id: uniqId,
        value: uniqId,
        text: name,
        content: (
          <div className="flex-1 flex justify-between items-center">
            <span>{name}</span>
            <span>
              <ClassVersionTag classVersion={group.class_version} />
            </span>
          </div>
        )
      };

      return option;
    })
  ];

  function handleAgeLevelChange(_: any, data: DropdownProps) {
    const values = String(data.value).split('-');
    const ageLevelId = Number(values[0]);
    const classVersion = values[1];

    if (ageLevelId === 0) {
      setDate(new Date());
      setStatus('all');
    }

    setAgeLevel(ageLevelId);
    setClassVersion(classVersion);
  }

  const hasObservers =
    usersData?.account_users && usersData.account_users.length > 0;

  const statusContainer = clsx(
    'grid gap-4 my-8 ',
    isFutureDate ? 'grid-cols-[1fr_1fr_1fr]' : 'grid-cols-[1fr_1fr_1fr_1fr_1fr]'
  );

  const hasCountObserver = data?.count > 0;

  const tableData = organizeData();

  function getCSVLink() {
    const columns: { [key: string]: string } = {
      email: 'email',
      hierarchy: 'hierarchy',
      status: 'status',
      expiration: 'expiration_date',
      training_date: 'training_date',
      first_test_start_date: 'test_start_date'
    };

    let params: { [key: string]: any } = { query: search, node_ids: nodesId };

    if (ageLevel !== 0) {
      params = {
        ...params,
        age_level_group_id: ageLevel,
        class_version: classVersion,
        status: status === 'all' ? null : status,
        date: moment(date).format('YYYY-MM-DD'),
        fields: [
          'name',
          ...columnState.filter(c => c.checked).map(c => columns[c.key])
        ]
      };
    }

    return qs.stringifyUrl(
      { url: '/api/v2/certification_dashboards/report', query: params },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
    );
  }

  return (
    <div className="mt-6">
      <Link
        className="text-sm font-bold"
        to={RoutesHelper.getPath('certifications-summary')}
      >
        <Icon name="angle left" />
        {i18n.ft(messages.back)}
      </Link>
      {isFutureDate && (
        <div className="flex justify-center p-2.5 mt-2 bg-[#F1E7D5] gap-1 border border-[#BB892C] rounded-lg">
          <Icon name="warning circle" color="black" />
          <span className="font-bold">
            {i18n.ft(messages.futureDateWarning)}
          </span>
        </div>
      )}

      <div className="my-6 flex flex-col md:flex-row justify-between gap-4">
        <div className="md:w-96">
          <Input
            placeholder={i18n.ft(messages.filters.searchPlaceholder)}
            onChange={debounce(handleSearch, 300)}
            icon="search"
            fluid
          />
        </div>
        <div className="flex flex-col-reverse sm:flex-row gap-4">
          {Boolean(ageLevel) && (
            <DatePicker
              selected={date}
              minDate={new Date()}
              onChange={date => {
                setDate(date!);
                if (moment(date).isAfter(moment())) {
                  if (status === 'seeking_initial') {
                    setStatus('all');
                  }
                }
              }}
            />
          )}

          <div className="sm:min-w-40 max-w-64">
            <HierarchyDropdown
              data={hierarchy}
              onChange={handleChange}
              placeholder={i18n.ft(messages.filters.organizationLevel)}
              keepOpenOnSelect
            />
          </div>

          <Select
            name="age_levels"
            label={i18n.ft(messages.ageLevel)}
            value={`${ageLevel}-${classVersion}`}
            placeholder={i18n.ft(messages.filters.selectAgeLevel)}
            options={ageLevelGroupOptions}
            onChange={handleAgeLevelChange}
            className="max-h-12"
          />
        </div>
      </div>

      <h1 className="text-2xl flex justify-between items-center">
        <>
          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-4">
            <span>
              {i18n.ft(messages.header)} ({tableData.length})
            </span>

            <AgeLevelTag
              ageLevel={getAgeLevel(ageLevel).name}
              code={getAgeLevel(ageLevel).code}
            />
          </div>
          {ageLevel !== 0 ? (
            <div className="flex items-center text-sm gap-6">
              {hasObservers && (
                <a
                  className="flex items-center gap-2 text-[#0A9CCA] font-semibold"
                  href={getCSVLink()}
                  download
                >
                  <span>{i18n.ft(messages.exportCsv)}</span>
                  <i className="fa-solid fa-arrow-down-to-bracket" />
                </a>
              )}
              <Dropdown
                text={i18n.ft(messages.columns)}
                className="text-[#0A9CCA] font-semibold"
              >
                <Dropdown.Menu>
                  {columnState.map((column, index) => (
                    <Dropdown.Item key={index}>
                      <Checkbox
                        label={column.name}
                        key={index}
                        checked={column.checked}
                        onChange={e => onChangeColumns(e, column.key)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div>
              {hasObservers && (
                <a
                  className="flex items-center gap-2 text-sm text-[#0A9CCA] font-semibold"
                  href={getCSVLink()}
                  download
                >
                  {i18n.ft(messages.exportCsv)}
                  <i className="fa-solid fa-arrow-down-to-bracket" />
                </a>
              )}
            </div>
          )}
        </>
      </h1>
      {(hasCountObserver || hasObservers) && ageLevel !== 0 && (
        <div className="overflow-x-auto overscroll-x-contain">
          <div className={statusContainer}>
            {!isFutureDate && (
              <>
                <StatusCard
                  arrow={false}
                  count={data.count}
                  title={i18n.ft(messages.allStatus)}
                  cardColor="bg-white"
                  hasShadow
                  value="all"
                  active={status === 'all'}
                  onClick={setStatus}
                />
                <StatusCard
                  arrow={false}
                  icon="search"
                  count={data.statuses.seeking_initial}
                  title={i18n.ft(messages.seekingInitial)}
                  cardColor="bg-white"
                  hasShadow
                  value="seeking_initial"
                  active={status === 'seeking_initial'}
                  titleBgColor="bg-[#077699]"
                  onClick={setStatus}
                />
              </>
            )}
            <StatusCard
              arrow={false}
              icon="active"
              count={data.statuses.active}
              title={i18n.ft(messages.current)}
              cardColor="bg-white"
              hasShadow
              value="active"
              active={status === 'active'}
              titleBgColor="bg-[#06874C]"
              onClick={setStatus}
            />
            <StatusCard
              arrow={false}
              icon="time"
              count={data.statuses.expiring_soon}
              title={i18n.ft(messages.expiringSoon)}
              cardColor="bg-white"
              hasShadow
              value="expiring_soon"
              active={status === 'expiring_soon'}
              titleBgColor="bg-action"
              onClick={setStatus}
            />
            <StatusCard
              arrow={false}
              icon="expired"
              count={data.statuses.expired}
              title={i18n.ft(messages.expired)}
              cardColor="bg-white"
              hasShadow
              value="expired"
              active={status === 'expired'}
              titleBgColor="bg-[#AC213A]"
              onClick={setStatus}
            />
          </div>
        </div>
      )}

      <div className="mt-6 mb-3">
        {isPending ? (
          <Loader active inline="centered" />
        ) : hasObservers ? (
          <ObserversTable columns={columns} data={tableData} />
        ) : (
          <div className="pt-10 pb-8 bg-white rounded-lg shadow-lg text-center">
            <div className="mx-1 font-semibold">
              {i18n.ft(messages.noObserversMessage, {
                ageLevel: getAgeLevel(ageLevel).name,
                hierarchy:
                  findHierarchy(nodesId)?.join(', ') || currentAccount?.name
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Observers;

const PATH = 'fe.components.learning_resources.coach_resources_menu';

const messages = {
  recommended: {
    key: `${PATH}.recommended`,
    defaultValue: 'Previously recommended to {{name}}'
  },
  recommendDescription: {
    key: `${PATH}.resources_description`,
    defaultValue: 'Resources previously recommended to {{name}}'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: "{{name}}'s Completed Resources"
  },
  completedDescription: {
    key: `${PATH}.recommendations_description`,
    defaultValue: 'Resources which {{name}} has completed'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Based on Classroom Scores for {{name}}'
  },
  classroomDescription: {
    key: `${PATH}.classroom_description`,
    defaultValue:
      "Recommendations based on {{name}} classroom's observation scores"
  },
  help: {
    key: `${PATH}.help`,
    defaultValue: 'Dropdown shows different categories'
  },
  searchText: {
    key: `${PATH}.search_text`,
    defaultValue: 'Results for "{{query}}"'
  }
};

export default messages;

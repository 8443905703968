import { useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { HierarchyContext } from './HierarchyContext';
import { useAccountHierarchy } from 'components/Organization/Hierarchy';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';

import {
  ACCOUNT_AREAS,
  useGetAccountsByArea
} from 'actions/accountManager/accounts';

interface HierarchyProviderProps {
  area: ACCOUNT_AREAS;
  children: React.ReactNode;
}

export function HierarchyProvider({ area, children }: HierarchyProviderProps) {
  const user = useCurrentUser();
  const { data = [] } = useGetAccountsByArea(area);
  const accountId = getCurrentAccount(user.current_account_id, data);
  const hierarchy = useAccountHierarchy(accountId);
  const [openNodeIds, setOpenNodeIds] = useState<number[]>([]);

  const value = {
    accountId,
    accountName: hierarchy.account,
    nodes: hierarchy.nodes,
    nodesMap: hierarchy.nodeNameMap,
    openNodeIds,
    setOpenNodeIds
  };

  return (
    <HierarchyContext.Provider key={accountId} value={value}>
      {children}
    </HierarchyContext.Provider>
  );
}

import * as R from 'ramda';
import { SERVICE_KEYS } from 'constants/services';

type SortProps = {
  sorted: 'ascending' | 'descending' | undefined;
  onClick: () => void;
};

type RowSortProps = {
  sort_by: string;
  sort_dir: string;
};

export function sortServices(services: SERVICE_KEYS[]) {
  const servicesOrder: SERVICE_KEYS[] = [
    'Observation',
    'Learning Resources',
    'PDLibrary',
    'ATP',
    'CTP',
    'MyTrainings',
    'Certification',
    'Calibration',
    'MMCI',
    'myCoach Connect',
    'Environmental Scales',
    'MTP',
    'Reports',
    'CBC',
    'VideoLibrary',
    'Moodle',
    'Totara',
    'Community',
    'myCLASSAdmin'
  ];

  let sortedServices: SERVICE_KEYS[] = [];

  R.forEach(serviceKey => {
    if (R.includes(serviceKey, services)) {
      sortedServices.push(serviceKey);
    }
  }, servicesOrder);

  return sortedServices;
}

export function getSortProps(
  column: string,
  sortState: RowSortProps,
  setSortState: Function
): SortProps {
  return {
    sorted: isSorted(column, sortState),
    onClick: () => handleSort(column, sortState, setSortState)
  };
}

function isSorted(column: string, sortState: RowSortProps) {
  const direction = sortState.sort_dir === 'asc' ? 'ascending' : 'descending';
  return sortState.sort_by === column ? direction : undefined;
}

function handleSort(
  column: string,
  sortState: RowSortProps,
  setSortState: Function
) {
  let sortBy = column;
  let sortDir = 'asc';

  if (sortState.sort_by === column && sortState.sort_dir === 'desc') {
    sortBy = '';
    sortDir = '';
  } else if (sortState.sort_by === column) {
    sortDir = sortState.sort_dir === 'asc' ? 'desc' : 'asc';
  }

  setSortState({ sort_by: sortBy, sort_dir: sortDir });
}

import * as R from 'ramda';
import RouteHelpers from 'helpers/routes';
import { Wrapper } from './Styled';
import { Navigate } from 'react-router-dom';
import { sortServices } from 'helpers/sort';
import { getService } from 'constants/services';
import useCurrentUser from 'hooks/useCurrentUser';
import ServiceTile from 'components/Dashboard/ServiceTile';

function Services() {
  const currentUser = useCurrentUser();

  function getServiceKeys() {
    const { services = [], roles = [] } = currentUser;
    let serviceKeys = services.map(service => service.key);

    // Ensures the certification service tile is always present
    // now that every user exists in core.
    if (!R.includes('Certification', serviceKeys)) {
      serviceKeys = R.append('Certification', serviceKeys);
    }

    if (R.includes('myclass_admin', roles)) {
      serviceKeys = R.append('myCLASSAdmin', serviceKeys);
    }

    if (
      R.includes('Moodle', serviceKeys) &&
      R.includes('Totara', serviceKeys)
    ) {
      serviceKeys = R.without(['Totara'], serviceKeys);
    }

    return serviceKeys;
  }

  function isValidUser() {
    const { first_name, last_name, email } = currentUser;
    let isValid = true;

    R.forEach(
      prop => {
        if (R.isNil(prop) || R.isEmpty(prop)) {
          isValid = false;
        }
      },
      [first_name, last_name, email]
    );

    return isValid;
  }

  function getServiceURL(key: string) {
    const { preferred_language } = currentUser;
    const lang = preferred_language || 'en';

    switch (key) {
      case 'MTP':
        return RouteHelpers.getPath('myclass-video_coaching', { id: 1, lang });
      case 'MMCI':
        return RouteHelpers.getPath('myclass-video_coaching', { id: 2, lang });
      case 'CBC':
        return RouteHelpers.getPath('myclass-video_coaching', { id: 3, lang });
      case 'ATP':
      case 'CTP':
        return RouteHelpers.getPath('myclass-training_portal', { lang });
      case 'Calibration':
        return RouteHelpers.getPath('myclass-calibration', { lang });
      case 'Certification':
        return RouteHelpers.getPath('certifications');
      case 'MyTrainings':
        return RouteHelpers.getPath('td-history');
      case 'Moodle':
      case 'Totara':
      case 'VideoLibrary':
        return RouteHelpers.getPath('canvas');
      case 'Observation':
        return RouteHelpers.getPath('myts-observations');
      case 'myCLASSAdmin':
        return RouteHelpers.getPath('myclass-myclass_admin', { lang });
      case 'Community':
        return RouteHelpers.getPath('community');
      case 'Reports':
        return RouteHelpers.getPath('myclass-contract_admin', { lang });
      case 'PDLibrary':
        return RouteHelpers.getPath('myts-resources');
      case 'myCoach Connect':
        return RouteHelpers.getPath('mycoach-connect');
      case 'Environmental Scales':
        return RouteHelpers.getPath('measure');
      case 'Learning Resources':
        return currentUser.roles.includes('lr_coach')
          ? RouteHelpers.getPath('coach-learning-resources')
          : RouteHelpers.getPath('learning-resources');
      default:
        return '';
    }
  }

  const serviceKeys = getServiceKeys();

  if (R.isEmpty(serviceKeys)) {
    return null;
  }

  // Users that haven't completed their required profile fields
  // should always be redirected to their account page
  if (!isValidUser()) {
    return <Navigate to={RouteHelpers.getPath('account')} replace />;
  }

  return (
    <Wrapper>
      {R.map(serviceKey => {
        const service = getService(serviceKey);
        const url = getServiceURL(serviceKey);
        return (
          <ServiceTile
            {...service}
            url={url}
            key={serviceKey}
            serviceKey={serviceKey}
          />
        );
      }, sortServices(serviceKeys))}
    </Wrapper>
  );
}

export default Services;

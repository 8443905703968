import moment from 'moment';
import { Select, Button } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import { useEffect, useMemo, useCallback } from 'react';
import { SelectedAccount } from 'types/api/Account';
import { getAccountAssessmentTemplates } from 'actions/envScales/accountAssessmentTemplates';
import {
  getObserverOptions,
  getTemplatesOptions,
  buildHierarchy
} from './utils';
import i18n from 'helpers/i18n';
import messages from './messages';
import HierarchyDropdown from 'components/Reports/Observation/HierarchyDropdown';
import { getHierarchyScoped } from 'actions/accounts';
import { getAllObservers } from 'actions/observers';
import {
  FilterContainer,
  FiltersDropdowns,
  LargeFiltersDropdowns,
  FiltersDatePicker,
  ClearSearchButton
} from './Styled';
import { classAgeLevels } from 'constants/ageLevels';
import DateRangePicker from 'components/Reports/Observation/DateRangePicker';
import useTimestamp from 'hooks/useTimestamp';

interface FilterProps {
  account?: SelectedAccount;
  state: {
    assessment_template_id?: string;
    age_level?: string;
    observer_guids?: string[];
    start_date?: string;
    end_date?: string;
    node_ids?: number[];
  };
  dates: {
    start_date: Date | null | undefined;
    end_date: Date | null | undefined;
  };
  isObserver: boolean;
  onFilterStatus: (filter: any) => void;
  onDatesChange: (
    startDate: Date | null | undefined,
    endDate: Date | null | undefined
  ) => void;
  onClear: () => void;
}

function Filters({
  account,
  state,
  onFilterStatus,
  onClear,
  dates,
  isObserver,
  onDatesChange
}: FilterProps) {
  const accountGuid = account === undefined ? '' : account.guid;
  const currentAccountId = account === undefined ? 0 : account.id;
  const { run: templatesRun, data: templatesData } = useAsync();
  const { run: hierarchyRun, ...hierarchyReq } = useAsync();
  const { run: observersRun, data: observersData } = useAsync();
  const [timestamp, refreshTimestamp] = useTimestamp();

  useEffect(() => {
    if (accountGuid !== '') {
      templatesRun(getAccountAssessmentTemplates(accountGuid));
    }

    hierarchyRun(getHierarchyScoped(currentAccountId));
    observersRun(getAllObservers(currentAccountId));
  }, [currentAccountId, accountGuid, templatesRun, hierarchyRun, observersRun]);

  const options = classAgeLevels;
  const tempOptions =
    getTemplatesOptions(templatesData?.account_assessment_templates) || [];

  const observersOptions = getObserverOptions(observersData);

  /**
   * Need to use `useCallback` and `useMemo` because of a bug with react-dropdown-tree-select
   * that would cause the entire list of selected options to be lost if any of the props change.
   */
  const hierarchy = useMemo(() => {
    return hierarchyReq.isSuccess
      ? buildHierarchy(hierarchyReq.data.hierarchy, state.node_ids)
      : [];
    // Disabling just because of state.node_ids (only needed for the first initial state).
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [hierarchyReq.isSuccess, hierarchyReq.data]);

  const handleChange = useCallback(
    (_: any, tags: any) => {
      const nodeIds = tags.map((t: any) => Number(t.value));
      onFilterStatus({ node_ids: nodeIds as string[] });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  function clear() {
    onClear();
    refreshTimestamp();
  }

  return (
    <FilterContainer>
      <FiltersDropdowns>
        <Select
          name="type"
          options={tempOptions}
          value={state.assessment_template_id}
          placeholder={i18n.ft(messages.classEnviroment)}
          onChange={(_, data) =>
            onFilterStatus({ assessment_template_id: data.value as string })
          }
        />
        <Select
          name="age_level"
          options={options}
          value={state.age_level}
          placeholder={i18n.ft(messages.ageLevel)}
          onChange={(_, data) =>
            onFilterStatus({ age_level: data.value as string })
          }
        />
      </FiltersDropdowns>
      <FiltersDatePicker style={{ backgroundColor: 'white' }}>
        <DateRangePicker
          startDate={dates.start_date}
          endDate={dates.end_date}
          onChange={([startDate, endDate]) => {
            onDatesChange(startDate, endDate);

            onFilterStatus({
              start_date: startDate
                ? moment(startDate).format('YYYY-MM-DD')
                : null
            });
            onFilterStatus({
              end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            });
          }}
        />
      </FiltersDatePicker>
      <LargeFiltersDropdowns>
        {!isObserver && (
          <Select
            name="observers"
            options={observersOptions}
            value={state.observer_guids}
            placeholder={i18n.ft(messages.observers)}
            onChange={(_, data) =>
              onFilterStatus({ observer_guids: data.value as string[] })
            }
            multiple
          />
        )}
        <HierarchyDropdown
          data={hierarchy}
          onChange={handleChange}
          placeholder={i18n.ft(messages.hierarchy)}
          key={timestamp}
        />
      </LargeFiltersDropdowns>
      <ClearSearchButton>
        <Button onClick={() => clear()}>{i18n.ft(messages.clear)}</Button>
        <div></div>
      </ClearSearchButton>
    </FilterContainer>
  );
}

export default Filters;

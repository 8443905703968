import RouteHelpers from 'helpers/routes';
import { ResourceType } from 'types/api/LearningResource';

export const DEFAULT_PER_PAGE = 20;
export const DEFAULT_CURRENT_PAGE = 1;
export function getHoursMinutes(seconds: number) {
  let hours = Math.floor((seconds % (3600 * 24)) / 3600);
  let minutes = Math.ceil((seconds % 3600) / 60);

  let totalHours = hours > 0 ? hours + (hours === 1 ? ' hr ' : ' hrs ') : '';

  return totalHours + minutes + ' min';
}

function padTimeUnit(time: number) {
  return String(time).padStart(2, '0');
}

export function getHoursMinutesSeconds(time: number) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.ceil((time % 3600) / 60);

  let totalTime = `${padTimeUnit(minutes)}:00`;

  if (hours > 0) {
    totalTime = `${padTimeUnit(hours)}:` + totalTime;
  }
  return totalTime;
}

/* various search results pages need to generate correct URLs depending on context and resource type */
const getDetailPageRoute = (id: number) =>
  RouteHelpers.getPath('learning-resources-detail-page', { id });
const getCoachDetailPageRoute = (id: number) =>
  RouteHelpers.getPath('coach-learning-resources-detail-page', { id });
const getPlaylistRoute = (id: number) =>
  RouteHelpers.getPath('learning-resources-playlist', { id });
const getCoachPlaylistRoute = (id: number) =>
  RouteHelpers.getPath('coach-learning-resources-playlist', { id });

const getResourceRouteForEducator = (id: number, type: ResourceType) => {
  if (type === 'playlist') {
    return getPlaylistRoute(id);
  }
  return getDetailPageRoute(id);
};
const getResourceRouteForCoach = (id: number, type: ResourceType) => {
  if (type === 'playlist') {
    return getCoachPlaylistRoute(id);
  }
  return getCoachDetailPageRoute(id);
};
export const getResourceRoute = (
  id: number,
  type: ResourceType,
  isCoach: boolean
) => {
  if (isCoach) {
    return getResourceRouteForCoach(id, type);
  }
  return getResourceRouteForEducator(id, type);
};

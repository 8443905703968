import useCurrentUser from './useCurrentUser';

type ACTIVE_FEATURE_FLAGS =
  | 'authorized_2008_age_levels'
  | 'members_upload'
  | 'enrollment_transfer'
  | 'learning_resources'
  | 'manage_account_members'
  | 'manage_hierarchy'
  | 'automated_feedback_v2';

interface UseFeatureFlags {
  isFeatureFlagEnabled: (featureFlagName: ACTIVE_FEATURE_FLAGS) => boolean;
}

function useFeatureFlags(): UseFeatureFlags {
  const userData = useCurrentUser();

  function isFeatureFlagEnabled(featureFlagName: ACTIVE_FEATURE_FLAGS) {
    return Boolean(userData?.feature_flags?.includes(featureFlagName));
  }

  return { isFeatureFlagEnabled };
}

export default useFeatureFlags;

const PATH = 'fe.pages.learning_resources.preferences_page';

const messages = {
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  learner: {
    key: `${PATH}.learner`,
    defaultValue: 'Learner Preferences'
  },
  choose: {
    key: `${PATH}.choose`,
    defaultValue:
      'Choose your preferred settings to tailor your learning experience.'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language'
  },
  age: {
    key: `${PATH}.age`,
    defaultValue: 'Age Level(s)'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'An error occurred, please try again later.'
  },
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Organization Hierarchy'
  }
};

export default messages;

import moment from 'moment';
import { Dropdown, Popup } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';
import centerIcon from 'images/envScales/centerIcon.svg';
import classroomIcon from 'images/envScales/classroomIcon.svg';
import observerIcon from 'images/envScales/observerIcon.svg';
import teacherIcon from 'images/envScales/teacherIcon.svg';
import calendarIcon from 'images/envScales/calendarIcon.svg';
import ageLevelIcon from 'images/envScales/ageLevelIcon.svg';
import calendarInProgressIcon from 'images/envScales/calendarInProgressIcon.svg';
import csvIcon from 'images/envScales/csvIcon.svg';
import pdfIcon from 'images/envScales/pdfIcon.svg';
import doubleCodeIcon from 'images/envScales/doubleCode.svg';
import { Assessment } from 'types/api/envScales/Assessment';
import * as R from 'ramda';
import RouteHelpers from 'helpers/routes';
import AssessmentLink from './AssessmentLink';
import { GenericCheckbox } from 'components/GenericCheckbox';

import {
  getTeacherNames,
  getPDFLink,
  getCSVLink,
  getAssessmentType,
  pillColors,
  ageLevelPillsColor,
  getAgeLevel
} from './utils';

import {
  Wrapper,
  CardHeader,
  CardBody,
  CardContent,
  CardIcon,
  FlexCenter,
  FlexEnd,
  ExportWrapper,
  ContentGrid,
  MetadataValue,
  TextBold,
  Pill,
  AgePill,
  ContentAssessment,
  CheckedSquare,
  NotCheckedSquare
} from './Styled';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { ENV_SCALES_URL } from 'constants/routes';

interface AssessmentCardProps {
  /** Assessment data. */
  assessment: Assessment;

  /** If `true`, this assessment belongs to the current user. */
  isOwnAssessment: boolean;

  /** If true is Admin. */
  isAdmin: boolean;

  /** If true is Observer. */
  isObserver: Boolean;

  /** If true is Observer. */
  isSupervisor: Boolean;

  /** If true, the user is selecting assessments. */
  isSelecting: Boolean;

  /** Function after a selection is done. */
  onAfterSelect: (id: number) => void;

  /** If true, the card is selected. */
  selected: boolean;

  /** Displays the Job Id instead of the teacher(s) name. */
  displayJobId?: boolean;
}

function AssessmentCard({
  assessment,
  isOwnAssessment,
  isAdmin,
  isObserver,
  isSupervisor,
  isSelecting,
  onAfterSelect,
  selected,
  displayJobId
}: AssessmentCardProps) {
  const isInProgress = assessment.status === 'open';
  const isCompleted = assessment.status === 'completed';
  const isAwaitingApproval = assessment.status === 'awaiting_approval';
  const { teachers, popupTeachers } = getTeacherNames(assessment.teachers);
  const assessmentType = getAssessmentType(assessment.assessment_template);
  const ageLevel = getAgeLevel(assessment.age_level);
  const doubleCodeText = assessment.primary_observer
    ? i18n.ft(messages.codePrimary)
    : i18n.ft(messages.codeSecondary);
  const { isFeatureFlagEnabled } = useFeatureFlags();

  function linkTo() {
    if (isInProgress && isOwnAssessment) {
      return RouteHelpers.getPath('measure-view', { id: assessment.id });
    } else if (isCompleted || (isAwaitingApproval && isOwnAssessment)) {
      return RouteHelpers.getPath('measure-completed', { id: assessment.id });
    } else if (isAwaitingApproval && (isAdmin || isSupervisor)) {
      return RouteHelpers.getPath('measure-summary', { id: assessment.id });
    }
  }

  function selectACard() {
    onAfterSelect(assessment.id);
  }

  return (
    <Wrapper
      as={isInProgress || isAwaitingApproval ? AssessmentLink : Wrapper}
      canLink={
        ((isInProgress && isOwnAssessment) ||
          (isAwaitingApproval && isOwnAssessment) ||
          (isAwaitingApproval && (isAdmin || isSupervisor))) &&
        !isSelecting
      }
      to={isInProgress || isAwaitingApproval ? linkTo() : null}
      aria-label={i18n.ft(messages.linkText, { data: assessment.taken_at })}
    >
      <CardHeader completed={isCompleted}>
        <FlexCenter>
          <CardIcon
            size="large"
            src={isCompleted ? calendarIcon : calendarInProgressIcon}
          />
          <span>{moment(assessment.taken_at).format('MM/DD/YYYY')}</span>

          {isCompleted && !isObserver && assessment.primary_observer && (
            <ExportWrapper>
              <FlexCenter
                as="a"
                target="_blank"
                href={getPDFLink(
                  assessment.id,
                  isFeatureFlagEnabled('automated_feedback_v2')
                )}
              >
                <img src={pdfIcon} alt={i18n.ft(messages.pdf)} />
              </FlexCenter>

              {assessment.includes_environments &&
              assessmentType !== 'environments' ? (
                <Dropdown
                  icon={<img src={csvIcon} alt={i18n.ft(messages.csv)} />}
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="a"
                      target="_blank"
                      href={getCSVLink(assessment)}
                      text={i18n.ft(messages.class_report)}
                    />
                    <Dropdown.Item
                      as="a"
                      target="_blank"
                      href={getCSVLink(assessment, true)}
                      text={i18n.ft(messages.environment_report)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <FlexCenter
                  as="a"
                  target="_blank"
                  href={getCSVLink(assessment)}
                >
                  <img src={csvIcon} alt={i18n.ft(messages.csv)} />
                </FlexCenter>
              )}
            </ExportWrapper>
          )}
          {isInProgress && assessment.returned_at && (
            <FlexEnd>
              <TextBold className="text-[#7aa010]">
                {i18n.ft(messages.review)}
              </TextBold>
            </FlexEnd>
          )}
          {isSelecting && (
            <FlexEnd>
              <GenericCheckbox
                onChange={selectACard}
                checked={selected}
                type="checkbox"
              >
                {selected ? <CheckedSquare /> : <NotCheckedSquare />}
              </GenericCheckbox>
            </FlexEnd>
          )}
        </FlexCenter>
      </CardHeader>
      <CardBody
        as={isCompleted ? AssessmentLink : CardBody}
        canLink={isCompleted}
        to={isCompleted ? linkTo() : null}
        aria-label={i18n.ft(messages.linkText, { data: assessment.taken_at })}
      >
        <ContentAssessment>
          <Pill
            backgroundColor={
              pillColors[assessmentType as keyof typeof pillColors]
            }
          >
            {i18n.ft(messages[assessmentType as keyof typeof messages])}
          </Pill>

          {assessment.double_coding ? (
            <Popup
              basic
              position="top left"
              style={{ backgroundColor: '#DFF2F9' }}
              trigger={<img src={doubleCodeIcon} alt={doubleCodeText} />}
              content={doubleCodeText}
            />
          ) : null}
        </ContentAssessment>
        <CardContent>
          <ContentGrid>
            <div>
              <FlexCenter>
                <CardIcon src={centerIcon} />
                <TextBold>{i18n.ft(messages.center)}</TextBold>
              </FlexCenter>
            </div>
            <MetadataValue>{assessment.center_name}</MetadataValue>
            <div>
              <FlexCenter>
                <CardIcon src={classroomIcon} />
                <TextBold>{i18n.ft(messages.classroom)}</TextBold>
              </FlexCenter>
            </div>
            <MetadataValue>{assessment.classroom_name}</MetadataValue>
            <div>
              <FlexCenter>
                <CardIcon src={observerIcon} />
                <TextBold>{i18n.ft(messages.observer)}</TextBold>
              </FlexCenter>
            </div>
            <MetadataValue>{assessment.observer_name}</MetadataValue>
            {displayJobId ? (
              <>
                <div>
                  <FlexCenter>
                    <CardIcon src={teacherIcon} />
                    <TextBold>{i18n.ft(messages.jobId)}</TextBold>
                  </FlexCenter>
                </div>
                <MetadataValue>{assessment.job_id}</MetadataValue>
              </>
            ) : (
              <>
                <div>
                  <FlexCenter>
                    <CardIcon src={teacherIcon} />
                    <TextBold>{i18n.ft(messages.teacher)}</TextBold>
                  </FlexCenter>
                </div>
                <Popup
                  basic
                  position="top left"
                  disabled={R.isEmpty(popupTeachers)}
                  style={{ backgroundColor: '#DFF2F9' }}
                  trigger={<MetadataValue>{teachers}</MetadataValue>}
                  content={popupTeachers}
                />
              </>
            )}

            <div>
              <FlexCenter>
                <CardIcon src={ageLevelIcon} />
                <TextBold>{i18n.ft(messages.classAssessment)}</TextBold>
              </FlexCenter>
            </div>
            <MetadataValue>
              <AgePill
                backgroundColor={
                  ageLevelPillsColor[
                    assessment.age_level as keyof typeof ageLevelPillsColor
                  ]
                }
              >
                {i18n.ft(messages[ageLevel as keyof typeof messages])}
              </AgePill>
            </MetadataValue>
          </ContentGrid>
        </CardContent>
      </CardBody>
    </Wrapper>
  );
}

export default AssessmentCard;

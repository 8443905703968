import moment from 'moment';
import timezone from 'moment-timezone';
import i18n from 'helpers/i18n';
import messages from './messages';
import { DropdownItemProps } from 'semantic-ui-react';
import { Assessment } from 'types/api/envScales/Assessment';
import { TagElement } from 'components/Measure/Create/InputWithTag';

export function validateDate(date: string) {
  const takenAt = moment(date, 'MM/DD/YYYY').startOf('day');
  const duration = moment.duration(moment().startOf('day').diff(takenAt));

  if (duration.asMonths() > 1) {
    return [i18n.ft(messages.warnings.date.past)];
  }
  return [];
}

export function validateTeachers(teachers: TagElement[]) {
  if (teachers.length === 0) {
    return [i18n.ft(messages.warnings.teachers.no_data)];
  }

  return [];
}

export type FormValues = {
  taken_at: string;
  number_of_cycles: number;
  center_guid: string;
  classroom_guid: string;
  age_level: string;
  job_id: string;
  observer_guid: string;
  double_coding: boolean;
  primary_observer: string;
  language: string;
  includes_environments: boolean;
  grade_id: number[];
  local_time_zone: string;

  // Tracks teacher names and guids, formats data on submit.
  teachers: { type: string; value: string; guid?: string }[];
};

export function initialEditValues(assessment: Assessment): FormValues {
  const teachers = assessment.teachers.map(teacher => ({
    type: 'text',
    value: teacher
  }));

  const educators =
    assessment.educators.map(educator => ({
      type: 'user',
      value: educator.name,
      guid: educator.guid
    })) ?? [];

  const editValues = {
    taken_at: moment(assessment.taken_at).format('MM/DD/YYYY'),
    local_time_zone: assessment.local_time_zone,
    number_of_cycles: assessment.observation?.observation_cycles.length || 0,
    center_guid: assessment.center_guid,
    classroom_guid: assessment.classroom_guid,
    age_level: assessment.age_level,
    job_id: assessment.job_id || '',
    observer_guid: assessment.observer_guid || '',
    double_coding: assessment.double_coding,
    primary_observer: assessment.primary_observer ? 'primary' : 'secondary',
    language: assessment.language,
    includes_environments: assessment.includes_environments,
    grade_id: assessment.grade_id,
    teachers: [...teachers, ...educators]
  };

  return editValues;
}

export function initialValues(
  ageLevelOptions: DropdownItemProps[],
  numberOfCycles?: number
): FormValues {
  let ageLevel = '';
  if (ageLevelOptions && ageLevelOptions.length) {
    ageLevel = ageLevelOptions[0].value as string;
  }

  return {
    taken_at: '',
    local_time_zone: timezone.tz.guess(),
    number_of_cycles: numberOfCycles || 0,
    center_guid: '',
    classroom_guid: '',
    teachers: [],
    age_level: ageLevel,
    job_id: '',
    observer_guid: '',
    double_coding: false,
    primary_observer: 'primary',
    language: 'en',
    includes_environments: false,
    grade_id: []
  };
}

export function getTimezoneOptions() {
  return timezone.tz.names().map(name => {
    const gmtOffset = timezone.tz(name).format('Z');
    const nameFormatted = name.replace(/_/g, ' ').replace(/\//g, ' / ');

    return {
      key: name,
      text: `(GTM${gmtOffset}) ${nameFormatted}`,
      value: name
    };
  });
}

import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import Masonry from 'react-masonry-css';
import PageLoader from 'components/PageLoader';
import TrainingCard from 'components/TrainingHistory/TrainingCard';
import { useState } from 'react';
import { Input } from 'components/Organization/Input';
import { Select } from 'components/Organization/Select';
import { Pagination } from 'components/Organization/Pagination';
import { useGetTrainingHistory } from 'actions/trainingHistory';
import { TrainingHistory } from 'types/api/TrainingHistory';
import { ClassIcon, filterOptions, sortOptions } from './utils';

const breakpointColumnsObj = {
  default: 3,
  768: 2,
  360: 1
};

export function TrainingList() {
  const [params, setParams] = useState({
    query: '',
    type: '',
    sort_by: 'start_date',
    sort_dir: 'desc',
    per_page: 9,
    page: 1
  });

  const { data, isPending } = useGetTrainingHistory(params);

  const handleSortChange = (sortOption: string) => {
    let sort_by = 'start_date';
    let sort_dir = 'desc';

    switch (sortOption) {
      case 'asc':
        sort_by = 'start_date';
        sort_dir = 'asc';
        break;
      case 'a-z':
        sort_by = 'name';
        sort_dir = 'asc';
        break;
      case 'z-a':
        sort_by = 'name';
        sort_dir = 'desc';
        break;
      default:
        sort_by = 'start_date';
        sort_dir = 'desc';
        break;
    }

    setParams(prev => ({ ...prev, sort_by, sort_dir }));
  };

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setParams(prev => ({
      ...prev,
      query: event.target.value,
      page: 1
    }));
  }

  const trainings = data?.training_histories ?? [];

  return (
    <section>
      <h2 className="font-sans font-bold text-white text-2xl bg-orange-500 px-6 py-3 rounded-t-xl">
        {i18n.ft(messages.trainingCourses)}
      </h2>

      <div className="mt-6">
        <div className="grid grid-cols-[2fr_1fr_1fr] gap-4 items-end">
          <Input
            type="search"
            label={i18n.ft(messages.search)}
            placeholder={i18n.ft(messages.searchPlaceholder)}
            onChange={debounce(handleSearch, 300)}
            className="border rounded-md h-10 p-2 placeholder:font-sans"
          />

          <Select
            label={i18n.ft(messages.filterByType)}
            value={params.type}
            onChange={(value: string) =>
              setParams(prev => ({ ...prev, type: value }))
            }
            options={filterOptions}
          />

          <Select
            label={i18n.ft(messages.sortBy)}
            value={
              params.sort_by === 'name'
                ? params.sort_dir === 'asc'
                  ? 'a-z'
                  : 'z-a'
                : params.sort_dir
            }
            onChange={(value: string) => handleSortChange(value)}
            options={sortOptions}
          />
        </div>

        {isPending ? (
          <PageLoader />
        ) : (
          <TrainingListView trainings={trainings} />
        )}
      </div>

      {trainings?.length > 0 && (
        <div className="mt-6 flex justify-center">
          <Pagination
            page={params.page}
            onPageChange={newPage =>
              setParams(prev => ({ ...prev, page: newPage }))
            }
            total={data?.pagination.total_pages || 1}
            goTo={false}
          />
        </div>
      )}
    </section>
  );
}

interface TrainingListViewProps {
  trainings: TrainingHistory[];
}

function TrainingListView({ trainings }: TrainingListViewProps) {
  return (
    <>
      <hr className="my-8 border-navy-100" />

      {trainings.length === 0 && (
        <div className="pt-8 pb-16 px-12 bg-white shadow-sm rounded-3xl flex flex-col items-center">
          <ClassIcon />

          <div className="w-1/2 mt-8 text-xl font-bold text-center">
            {i18n.ft(messages.noTrainingsTitle)}
          </div>

          <div className="w-1/2 mt-3 text-base text-center">
            {i18n.ft(messages.noTrainingsContent)}
          </div>
        </div>
      )}

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex -ml-[15px] w-auto"
        columnClassName="pl-[15px] bg-clip-padding"
      >
        {trainings.map(training => (
          <TrainingCard key={training.id} training={training} />
        ))}
      </Masonry>
    </>
  );
}

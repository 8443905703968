const PATH = 'fe.pages.coach.recommend';

const messages = {
  select: {
    key: `${PATH}.select`,
    defaultValue: 'Select Educator'
  },
  ageLevel: {
    key: `${PATH}.age_level`,
    defaultValue: 'Select Age Level for Classroom Scores'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search All Resources'
  }
};

export default messages;

const PATH = 'fe.pages.learning_resources.educator_home_page';

const messages = {
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search All Resources'
  },
  notAllowed: {
    key: `${PATH}.not_allowed`,
    defaultValue: 'As coach, you are not allowed to view this page'
  }
};

export default messages;

const PATH = 'fe.components.organization.action_popup';

const messages = {
  membersArchived: {
    key: `${PATH}.members_archived`,
    defaultValue: 'Members Archived '
  },
  archive: {
    key: `${PATH}.archive`,
    defaultValue: 'Archive Selected ({{count}})'
  }
};

export default messages;

import * as React from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { Navigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { AgeLevel } from 'types/api/envScales/AgeLevel';
import { Assessment } from 'types/api/envScales/Assessment';
import { getAgeLevel } from 'actions/envScales/assessmentTemplates';
import { getAssessment } from 'actions/envScales/assessments';

interface MeasureContextValue {
  setAssessment: (assessment: Assessment) => void;
  assessment: Assessment;
  ageLevel: AgeLevel;
}

const MeasureContext = React.createContext<MeasureContextValue | null>(null);
MeasureContext.displayName = 'MeasureContext';

interface MeasureProviderProps {
  /** Assessment ID. */
  assessmentId: number;

  /** React Node. */
  children: React.ReactNode | ((value: MeasureContextValue) => React.ReactNode);

  /** If `true`, the CLASS assessment will contain all cycle scores.  */
  expanded?: boolean;
}

export function MeasureProvider({
  assessmentId,
  children,
  expanded
}: MeasureProviderProps) {
  const { run: assessmentRun, ...assessmentReq } = useAsync();
  const { run: ageLevelRun, ...ageLevelReq } = useAsync();

  React.useEffect(() => {
    assessmentRun(getAssessment(assessmentId, { expanded }), {
      onSuccess: data => {
        ageLevelRun(getAgeLevel(data.assessment_template_id, data.age_level));
      }
    });
  }, [assessmentRun, ageLevelRun, assessmentId, expanded]);

  if (assessmentReq.isPending || ageLevelReq.isPending) {
    return <PageLoader />;
  }

  if (assessmentReq.isError) {
    return <Navigate replace to={RouteHelpers.getPath('measure')} />;
  }

  const value: MeasureContextValue = {
    setAssessment: assessmentReq.setData,
    assessment: assessmentReq.data,
    ageLevel: ageLevelReq.data
  };

  return (
    <MeasureContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </MeasureContext.Provider>
  );
}

// Typescript function overloads
type UseMeasureProps = { skipContextCheck: boolean };
export function useMeasure(): MeasureContextValue;
export function useMeasure(props: UseMeasureProps): MeasureContextValue | null;

export function useMeasure(props?: UseMeasureProps) {
  const context = React.useContext(MeasureContext);
  const { skipContextCheck } = props || {};

  if (!context && !skipContextCheck) {
    throw new Error('useMeasure must be used within <MeasureProvider />');
  }

  return context;
}

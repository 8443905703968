import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { useState } from 'react';
import { TextButton } from 'components/Organization/TextButton';
import { PrimaryButton } from 'components/Organization/PrimaryButton';

interface EditableFieldProps {
  /** Field label. */
  label: string;

  /** Field value to be displayed in non edit mode. */
  value: React.ReactNode;

  /** When `true`, top-border is added to the field. */
  border?: boolean;

  /** Additional classes. */
  className?: string;

  /** Initial value for the control. */
  initialValue?: any;

  /** Callback fired when the field is saved. */
  onSave?: (value: any) => void;

  /** Custom control to be rendered in edit mode. */
  control?: (options: {
    value: any;
    setValue: (value: any) => void;
  }) => React.ReactNode;
}

export function EditableField({
  label,
  value,
  onSave,
  control,
  className,
  initialValue,
  border = true
}: EditableFieldProps) {
  const [internalValue, setInternalValue] = useState<any>(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const classes = clsx(
    'py-6 flex gap-8 justify-between',
    border && 'border-t border-[#D7DFE8]',
    className
  );

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    await Promise.resolve(onSave?.(internalValue));
    setIsEditing(false);
    setIsSaving(false);
  };

  const handleCancel = () => {
    if (isSaving) {
      return;
    }

    setInternalValue(initialValue);
    setIsEditing(false);
  };

  return (
    <div className={classes}>
      {isEditing ? (
        <div className="w-full flex flex-col gap-4">
          {control?.({ value: internalValue, setValue: setInternalValue })}

          <div className="flex justify-end gap-6">
            <TextButton size="small" onClick={handleCancel}>
              {i18n.ft(messages.cancel)}
            </TextButton>
            <PrimaryButton size="small" onClick={handleSave}>
              {i18n.ft(messages.save)}
            </PrimaryButton>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-base font-semibold">{label}</div>
          <div className="mt-1.5 text-base">{value || '--'}</div>
        </div>
      )}

      {control && !isEditing && (
        <button
          onClick={() => setIsEditing(true)}
          className="w-10 h-10 shrink-0 flex items-center justify-center text-[#6E2262]"
        >
          <i className="fa-solid fa-pencil" />
        </button>
      )}
    </div>
  );
}

const PATH = 'pages.organization.hiearchy.node_view';

const messages = {
  details: {
    key: `${PATH}.details`,
    defaultValue: 'Details'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Hierarchy Association'
  },
  externalId: {
    key: `${PATH}.external_id`,
    defaultValue: 'External ID'
  },
  fundingStreams: {
    key: `${PATH}.funding_streams`,
    defaultValue: 'Funding Streams'
  },
  programDescriptors: {
    key: `${PATH}.program_descriptors`,
    defaultValue: 'Program Descriptors'
  },
  gradeLevel: {
    key: `${PATH}.grade_level`,
    defaultValue: 'Grade Level'
  },
  specialPopulation: {
    key: `${PATH}.special_population`,
    defaultValue: 'Special Population'
  },
  viewMembers: {
    key: `${PATH}.view_members`,
    defaultValue: 'View Members'
  }
};

export default messages;

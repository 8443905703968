const PATH = 'fe.pages.organization.edit_hierarchy';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Edit Hierarchy'
  },
  backToHierarchy: {
    key: `${PATH}.back_to_hierarchy`,
    defaultValue: 'Back to Hierarchy'
  }
};

export default messages;

import { useEffect } from 'react';
import DetailPage from './DetailPage';
import RoutesHelper from 'helpers/routes';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PlaylistDetailPage from 'pages/LearningResources/Playlist/PlaylistDetailPage';
import PreferencesPage from 'pages/LearningResources/PreferencesPage';
import LearningResourcesProvider from './LearningResourcesContext';
import useCurrentUser from 'hooks/useCurrentUser';
import EducatorHeader from 'components/LearningResources/EducatorHeader';
import EducatorHomePage from './EducatorHomePage';

function LearningResources() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    if (!isFeatureFlagEnabled('learning_resources')) {
      navigate(RoutesHelper.getPath('app'));
    }
  }, [isFeatureFlagEnabled, navigate, user]);

  return (
    <LearningResourcesProvider>
      <Routes>
        <Route element={<EducatorHeader />}>
          <Route path=":id" element={<DetailPage />} />
          <Route path="playlist/:id" element={<PlaylistDetailPage />} />
          <Route path="preferences" element={<PreferencesPage />} />
          <Route index element={<EducatorHomePage />} />
        </Route>
      </Routes>
    </LearningResourcesProvider>
  );
}

export default LearningResources;

import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { UserMe } from 'types/api/User';
import { Icon, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import RouteHelpers from 'helpers/routes';
import DisplayStatusMessage from './DisplayStatusMessage';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';
import { HeaderSection, DimensionScoreIcon } from './Styled';
import { flexColReverse } from 'components/ReliabilityTest/constants';
import { MessageBox, Section } from 'components/ReliabilityTest/Styled';
import { Status as StatusIcon } from 'components/Reports/Reliability/View';
import { REPORT_STATUS, TEST_OUTCOME } from 'types/api/ReliabilityReport';
import DoughnutChartPanel from 'components/ReliabilityTest/DoughnutChartPanel/DoughnutChartPanel';
import ShowFirstAttemptMessage from 'components/ReliabilityTest/ScoreReport/ResultSection/ShowFirstAttemptMessage/ShowFirstAttemptMessage';
import CategoryResult from 'components/KnowledgeTest/ResultSection/CategoryResult/CategoryResult';
import attempt1Icon from 'images/reliabilityTest/attempt1.svg';
import { TestAttemptIteration } from 'types/api/TestAttempt';

type Dimension = {
  abbreviation: string;
  status: REPORT_STATUS;
};

export interface ResultSectionProps {
  result: TEST_OUTCOME;
  title: string;
  testScore: number;
  dimensions?: Dimension[];
  attemptNumber: TestAttemptIteration;
  trainerView?: boolean;
  testType?: 'reliability' | 'preparation' | 'knowledge';
  categories?: any;
  threeOfSix?: any;
  framework?: 'class_2008' | 'class_2nd_edition';
  role?: 'observer' | 'trainer';
}

type stateProps = {
  user: UserMe;
  locale: string;
};

type Category = {
  name: string;
  score: number;
};

function ResultSection({
  result,
  title,
  dimensions,
  testScore,
  attemptNumber,
  trainerView,
  testType,
  categories,
  threeOfSix,
  framework,
  role
}: ResultSectionProps) {
  const [failedTwoOfFive, setFailedTwoOfFive] = useState(false);
  const [eachCategoryOverFifty, setEachCategoryOverFifty] = useState(true);
  const currentUser = useSelector((state: stateProps) => state.user);
  const knowledgeTest = testType === 'knowledge' ? true : false;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [sortedCategories, setSortedCategories] = useState<Category[]>([]);
  const baseOrderCategories = useMemo(() => {
    return [
      'Interactions and Facilitation',
      'Trainer Processes',
      'Training Delivery',
      'Coding Processes',
      'CLASS Framework'
    ];
  }, []);

  useEffect(() => {
    if (!dimensions) {
      return;
    }

    const dimensionFailed = dimensions.find(
      dimension => dimension.status === 'failed'
    );

    if (dimensionFailed) {
      setFailedTwoOfFive(true);
    }
  }, [dimensions]);

  useEffect(() => {
    if (!threeOfSix) {
      return;
    }

    const eachCategoryOverFifty = threeOfSix.find(
      (category: { status: string }) => category.status === 'failed'
    )?.status;

    if (eachCategoryOverFifty) {
      setEachCategoryOverFifty(false);
    }
  }, [threeOfSix]);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const compararPorNombre = (a: { name: string }, b: { name: string }) => {
      return (
        baseOrderCategories.indexOf(a.name) -
        baseOrderCategories.indexOf(b.name)
      );
    };

    const sortedArray = categories.sort(compararPorNombre);

    setSortedCategories(sortedArray);
  }, [categories, baseOrderCategories]);

  function returnGridColumns() {
    /** For Knowlede Test we need only one column (5 rows 1 column each) */
    if (knowledgeTest) {
      return 'grid-cols-1';
    }
    /** Reliability test when there are 8 dimensions.
     * Two rows of 4 dimensions (4 columns each)
     */
    if (dimensions?.length === 4) {
      return 'grid-cols-4';
    }

    /** Releability test when there are 10 dimensions.
     * Two rows of 5 dimensions (5 columns each)
     */
    return 'grid-cols-5';
  }
  /* both panels should receive the same layout CSS */
  const dimensionGridCols = returnGridColumns();

  /** For Knowledge Test the criteria is three_of_six_per_category
   * For Reliability Test the criteria is two_of_five_per_dimension
   */
  function showReliabilityOrKnowledgeTestMessage() {
    let message = '';
    if (knowledgeTest) {
      message = i18n.ft(messages.categoryAtLeastFifty);
    } else {
      failedTwoOfFive
        ? (message = i18n.ft(messages.failTwoOfFiveRule))
        : (message = i18n.ft(messages.twoOfFiveRule));
    }
    return message;
  }

  function iconBackGroundColor() {
    if (knowledgeTest) {
      if (eachCategoryOverFifty) {
        return colors.reliability.passVideo;
      } else {
        return colors.reliability.failedAttempt;
      }
    }

    if (failedTwoOfFive) {
      return colors.reliability.failedAttempt;
    } else {
      return colors.reliability.passVideo;
    }
  }

  function showPassOrFailIcon() {
    if (knowledgeTest) {
      if (eachCategoryOverFifty) {
        return 'check';
      } else {
        return 'close';
      }
    }
    if (failedTwoOfFive) {
      return 'close';
    } else {
      return 'check';
    }
  }

  function getNoteText() {
    if (role === 'observer') {
      return i18n.ft(messages.observerNote);
    }

    // For trainer role
    return framework === 'class_2nd_edition'
      ? knowledgeTest
        ? i18n.ft(messages.observerKnowledgeTestNote)
        : i18n.ft(messages.trainerSecondEditionNote)
      : i18n.ft(messages.trainerFirstEditionNote);
  }

  return (
    <Section knowledgeTest className="mb-2 PrintPaddingBottom">
      {trainerView ? null : (
        <HeaderSection tabIndex={0}>
          {result === 'passed' ? (
            <>
              <div className="mx-auto">
                {i18n.ft(messages.congratulationsMessage, {
                  value: currentUser.first_name
                })}
              </div>
              <div className="mt-4 mx-5 text-center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.ft(messages.passMessage)
                  }}
                />
                {title}!
              </div>
            </>
          ) : (
            <>
              <div className="mx-auto">
                {i18n.ft(messages.sorryMessage, {
                  value: currentUser.first_name
                })}
              </div>
              <div
                className="mx-auto mt-4 mx-5 text-center sm:mb-0 md:mb-8"
                style={{
                  width: `${knowledgeTest && isDesktop ? '55%' : ''}`
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.ft(messages.notPassMessage)
                  }}
                />
                {title}.
              </div>
            </>
          )}
        </HeaderSection>
      )}
      <div className="grid grid-cols-1 gap-2 auto-rows-[1fr] sm:mt-0 md:mt-8 items-center justify-center sm:gap-12 md:grid-cols-2 md:gap-2 lg:gap-12 PrintPageBreakAfter">
        <DoughnutChartPanel
          scoreAbove={i18n.ft(messages.scoreAtLeast)}
          scoreBelow={i18n.ft(messages.scoreAtLeast)}
          testScore={testScore}
          videoCriteria={i18n.ft(
            knowledgeTest ? messages.questionsCorrectly : messages.videoCriteria
          )}
          knowledgeTest={knowledgeTest}
        />

        <div className={`mt-12 md:mt-0 ${flexColReverse}`}>
          {/* bottom item */}
          <MessageBox className="PrintBackgroundColor PrintMarginY mt-5">
            <div className="flex justify-center mb-auto">
              <div className="align-middle pt-4 pb-4 mr-2 text-xl" tabIndex={0}>
                <span
                  className="text-xl md:text-lg lg:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: showReliabilityOrKnowledgeTestMessage()
                  }}
                  style={{ marginRight: '5px' }}
                />
              </div>
              <div className="min-w-8 self-center">
                <StatusIcon
                  iconColor={colors.white}
                  iconBackGroundColor={iconBackGroundColor()}
                >
                  <Icon
                    name={showPassOrFailIcon()}
                    size="tiny"
                    className="align-middle translate-y-[1px]"
                    style={{ margin: 'auto' }}
                  />
                </StatusIcon>
              </div>
            </div>
          </MessageBox>

          {/* middle item */}
          <div className="text-center mx-2 md:mx-0 mb-auto mt-4">
            {i18n.ft(
              knowledgeTest ? messages.eachCategory : messages.twoOfFiveMessage
            )}
          </div>

          {/* top item */}
          <div className="mx-auto md:mx-0 mb-auto min-h-[145px] flex flex-nowrap PrintDimensionGrid">
            <div
              className={`grid ${dimensionGridCols} ${
                knowledgeTest ? '' : 'gap-2'
              } items-center text-center text-white justify-items-center sm:mx-16 md:mx-auto lg:mx-0 lg:w-full`}
            >
              {knowledgeTest
                ? sortedCategories.map((category: Category, idx: number) => {
                    return (
                      <div key={idx} className="w-full contents">
                        <CategoryResult
                          name={category.name}
                          score={category.score}
                        />
                      </div>
                    );
                  })
                : null}
              {dimensions &&
                dimensions.map((dimension: Dimension, idx: number) => {
                  return (
                    <DimensionScoreIcon
                      key={idx}
                      passed={dimension.status === 'passed' ? true : false}
                    >
                      <Icon
                        name={dimension.status === 'passed' ? 'check' : 'close'}
                        size="tiny"
                        className="align-middle"
                        style={{ margin: '0' }}
                      />
                      <div className="font-bold">
                        {dimension.abbreviation.toUpperCase()}
                      </div>
                    </DimensionScoreIcon>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {!trainerView && result === 'passed' && (
        <MessageBox className="PrintBackgroundColor mt-5">
          <div className="flex justify-around p-10">
            <img src={attempt1Icon} className="p-5" alt="Note" />

            <div className="flex justify-around flex-col text-center">
              <div className="font-bold">{getNoteText()}</div>
              <div>
                <Button
                  className="shrink"
                  content={i18n.ft(messages.certifications)}
                  color="blue"
                  as="a"
                  href={RouteHelpers.getPath('certifications')}
                />
              </div>
            </div>
          </div>
        </MessageBox>
      )}

      <div className="justify-center my-10 PrintMaginBottom">
        <DisplayStatusMessage
          result={result}
          attemptNumber={attemptNumber}
          trainerView={trainerView}
          knowledgeTest={knowledgeTest}
        />
      </div>

      {knowledgeTest || trainerView ? null : (
        <ShowFirstAttemptMessage
          attemptNumber={attemptNumber}
          result={result}
        />
      )}

      {attemptNumber === 2 && result === 'failed' ? (
        <MessageBox className="PrintBackgroundColor">
          <div className="justify-center mt-2 py-8 px-4 md:px-16 PrintMarginBottom PrintPaddingTop PrintPaddingBottom">
            <div className="align-middle pt-4 PrintPaddingTop">
              <div className="flex">
                <img
                  src={lampIcon}
                  alt={i18n.ft(messages.attemptTip)}
                  className="self-start mr-4"
                />
                <div className="mb-4 mx-auto PrintMarginBottom">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: i18n.ft(messages.attemptTwoAdditional)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </MessageBox>
      ) : null}
    </Section>
  );
}

export default ResultSection;

import { sortByName } from './utils';
import { useNode } from './useNode';
import { NodeProvider } from './NodeProvider';

export function NodeChildren() {
  const { accountId, isOpen, depth, node, as: NodeComponent } = useNode();

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {sortByName(node.children).map(child => (
        <NodeProvider
          key={child.id}
          as={NodeComponent}
          depth={depth + 1}
          node={child}
          accountId={accountId}
        >
          <NodeComponent />
        </NodeProvider>
      ))}
    </>
  );
}

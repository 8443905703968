const PATH = 'fe.components.learning_resources.show_resources_modal';

const messages = {
  all: {
    key: `${PATH}.all`,
    defaultValue: 'All educators you are matched with'
  },
  select: {
    key: `${PATH}.select`,
    defaultValue: 'Select Educators to Recommend To'
  },
  teachers: {
    key: `${PATH}.teachers`,
    defaultValue: 'Select educators'
  },
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommendation Settings'
  },
  message: {
    key: `${PATH}.message`,
    defaultValue: 'Write a Message'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue:
      'Send this message as an email to notify users of this recommendation.'
  },
  explanation: {
    key: `${PATH}.explanation`,
    defaultValue:
      'Enter an explanation, start a conversation, or pose a question to deepen understanding of this recommendation.'
  },
  previously: {
    key: `${PATH}.previously`,
    defaultValue: "Only if the resource hasn't been recommended previously"
  },
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search educators'
  },
  force: {
    key: `${PATH}.force`,
    defaultValue: 'Recommend even if it has been recommended previously'
  },
  noDuplication: {
    key: `${PATH}.no_duplication`,
    defaultValue: "Only if the resource hasn't been recommended previously"
  },
  older: {
    key: `${PATH}.older`,
    defaultValue:
      "Only if the resource hasn't been recommended in the past 30 days"
  }
};

export default messages;

import { clsx } from 'clsx';
import { useNode } from './useNode';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

interface NodeProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (node: AccountHierarchy) => void;
}

export function Node({ children, className, onClick }: NodeProps) {
  const { depth, node, isOpen, setIsOpen } = useNode();
  const hasChildren = node.children.length > 0;
  const paddingLeft = 24 * depth;

  const handleNodeClick = () => {
    setIsOpen(!isOpen);
    onClick?.(node);
  };

  const handleNodeKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setIsOpen(!isOpen);
      onClick?.(node);
    }
  };

  const classes = clsx(
    'py-2 px-6 flex items-center justify-between border-b border-[#D8D9D9] last:border-b-0 group',
    'hover:bg-[#F7F8F9] focus:bg-[#F7F8F9]',
    className
  );

  return (
    <div
      tabIndex={0}
      role="button"
      className={classes}
      style={{ paddingLeft }}
      onClick={handleNodeClick}
      onKeyDown={handleNodeKeyDown}
      aria-expanded={hasChildren ? (isOpen ? 'true' : 'false') : undefined}
    >
      {children}
    </div>
  );
}

import * as React from 'react';
import moment from 'moment';
import timezone from 'moment-timezone';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Box } from 'components/Box';
import { Input } from 'components/Measure/Input';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { AgeLevel } from 'types/api/envScales/AgeLevel';
import { Assessment } from 'types/api/envScales/Assessment';
import TimeRangeInput from 'components/Measure/TimeRangeInput';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { updateObservationCycle } from 'actions/envScales/observationCycles';
import MetaDataCategoryForm from '../MetaDataCategoryForm';
import MetaDataMultiSelect from '../MetaDataMultiSelect';
import {
  FormRow,
  CategoriesGrid,
  VerticalSeparator,
  FormColumn
} from './Styled';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import {
  adultsError,
  childrenError,
  cycleTimesError,
  getAdultWarnings,
  getChildrenWarnings,
  getCycleTimeWarnings,
  valueForField,
  numericFieldsWarnings,
  getInitialState,
  NumericFieldStateType,
  EIGHT_MINUTES_IN_MILLISECONDS,
  THIRTY_MINUTES_IN_MILLISECONDS
} from './utils';
import Warning from 'components/Measure/Warning';
import * as R from 'ramda';

import MetaDataFields from '../MetaDataFields';
import MetaDataSelectOne from '../MetaDataSelectOne';
import { SectionCollapse } from 'components/SectionCollapse';

interface ICycleDetails {
  adults: string | number;
  children: string | number;
  start_time: string;
  end_time: string;
}

interface CycleDetailsFormProps {
  /** Observation Cycle. */
  cycle: ObservationCycle;

  /** CLASS Assessment. */
  assessment: Assessment;

  /** Environmental Scales Age Level. */
  ageLevel: AgeLevel;

  /** Custom className for wrapper component. */
  className?: null | string;
}

function CycleDetailsForm({
  assessment,
  cycle,
  ageLevel,
  className
}: CycleDetailsFormProps) {
  const [touchedTime, setTouchedTime] = useState(false);
  const [touchedAdults, setTouchedAdults] = useState(false);
  const [touchedChildren, setTouchedChildren] = useState(false);
  const [state, setState] = useState(
    getInitialState(cycle, assessment.local_time_zone)
  );
  const [errors, setErrors] = useState<{ cycleTime?: string }>({});
  const [cycleTimeWarnings, setCycleTimeWarnings] = useState<Array<string>>([]);
  const [adultsWarnings, setAdultsWarnings] = useState<Array<string>>([]);
  const [childrenWarnings, setChildrenWarnings] = useState<Array<string>>([]);
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();

  const numericFields = ageLevel.meta_data_categories.filter(
    category => category.data_type === 'numeric'
  );
  const hasNumericFields = numericFields.length > 0;
  const numericFieldMapping = numericFields.map(field => {
    return [field.t_name, valueForField(field.id, cycle)];
  });
  const [numericFieldsState, setNumericFieldsState] =
    useState<NumericFieldStateType>(Object.fromEntries(numericFieldMapping));
  const numberOfNumericFieldsInARow = 2;
  const groupedNumericFields = R.splitEvery(
    numberOfNumericFieldsInARow,
    numericFields
  );

  const selectOneFields = ageLevel.meta_data_categories
    .filter(category => category.assessment_template.class_version === 1)
    .filter(category => category.data_type === 'select_one');
  const hasSelectOneFields = selectOneFields.length > 0;

  useDebouncedEffect(
    () => {
      if (touchedTime || touchedAdults || touchedChildren) {
        const observationId = assessment.observation!.id;

        const payload = {
          ...state,
          start_time: timezone.tz(
            `${assessment.taken_at} ${state.start_time}`,
            assessment.local_time_zone
          ),
          end_time: timezone.tz(
            `${assessment.taken_at} ${state.end_time}`,
            assessment.local_time_zone
          )
        };

        updateObservationCycle(observationId, cycle.id, payload);
      }
    },
    300,
    [touchedTime, touchedAdults, touchedChildren, state]
  );

  useDebouncedEffect(
    () => {
      if (touchedTime) {
        setCycleTimeWarnings(
          getCycleTimeWarnings(
            assessment.taken_at,
            state.start_time,
            state.end_time
          )
        );
      }
    },
    800,
    [touchedTime, state.start_time, state.end_time]
  );

  useDebouncedEffect(
    () => {
      if (touchedAdults) {
        setAdultsWarnings(getAdultWarnings(state.adults as number));
      }
    },
    800,
    [touchedAdults, state.adults]
  );

  useDebouncedEffect(
    () => {
      if (touchedChildren) {
        setChildrenWarnings(getChildrenWarnings(state.children as number));
      }
    },
    800,
    [touchedChildren, state.children]
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.target.focus();
    const stateUpdated = { ...state, [event.target.name]: event.target.value };
    setState(stateUpdated);

    if (event.target.name === 'adults') {
      setTouchedAdults(true);
    } else if (event.target.name === 'children') {
      setTouchedChildren(true);
    }

    updateCyclesValidations(cycle.cycle_number, stateUpdated);
  }

  function updateCyclesValidations(cyclesNumber: number, state: ICycleDetails) {
    if (R.isEmpty(cyclesErrors)) {
      return;
    }

    let newErrors = R.clone(cyclesErrors);
    newErrors[cyclesNumber]!.adults = validateNumericField(state.adults);
    newErrors[cyclesNumber]!.children = validateNumericField(state.children);
    updateCycleErrors(newErrors);
  }

  function validateNumericField(value: string | number) {
    const valueAsNumber = Number(value);

    if (valueAsNumber <= 0) {
      return i18n.ft(messages.errors.numberRequired);
    } else {
      return null;
    }
  }

  function validateTime(times: Array<String>) {
    const [start_time, end_time] = times;

    if (start_time && end_time) {
      const diff = moment
        .utc(`${assessment.taken_at} ${end_time}`)
        .diff(moment.utc(`${assessment.taken_at} ${start_time}`));

      if (diff < EIGHT_MINUTES_IN_MILLISECONDS) {
        return setErrors({
          ...errors,
          cycleTime: i18n.ft(messages.shortCycleTime)
        });
      } else if (diff > THIRTY_MINUTES_IN_MILLISECONDS) {
        return setErrors({
          ...errors,
          cycleTime: i18n.ft(messages.longCycleTime)
        });
      }

      setCycleTimeWarnings(
        getCycleTimeWarnings(
          assessment.taken_at,
          state.start_time,
          state.end_time
        )
      );
    } else if (R.isEmpty(start_time)) {
      return setErrors({
        ...errors,
        cycleTime: i18n.ft(messages.errors.startCycleTime.required)
      });
    } else if (R.isEmpty(end_time)) {
      return setErrors({
        ...errors,
        cycleTime: i18n.ft(messages.errors.endCycleTime.required)
      });
    }

    setErrors({ ...errors, cycleTime: undefined });
  }

  function handleTimeChange(value: string[]) {
    const [start_time, end_time] = value;
    setState({ ...state, start_time, end_time });
    setTouchedTime(true);
    validateTime([start_time, end_time]);
  }

  function cycleTimeErrorMessage() {
    if (touchedTime) {
      return errors.cycleTime;
    }

    return cycleTimesError(cyclesErrors, cycle.cycle_number);
  }

  const isClassFirstEdition =
    assessment.assessment_template.class_version === 1;
  const isClassSecondEdition =
    assessment.assessment_template.class_version === 2;

  const categoriesClassFirstEdition = ageLevel.meta_data_categories
    .filter(category => category.assessment_template.class_version === 1)
    .filter(category => category.data_type === 'multi_select')
    .filter(category => category.choices.length > 0);

  const categoriesClassSecondEdition = ageLevel.meta_data_categories
    .filter(category => category.assessment_template.class_version === 2)
    .filter(category => category.choices.length > 0);

  const commonFields = () => (
    <>
      <Warning
        warningTexts={adultsWarnings}
        maxWidth={290}
        dismissible
        onDismiss={() => setAdultsWarnings([])}
      >
        <Input
          min="0"
          fluid
          required
          type="number"
          name="adults"
          label={i18n.ft(messages.adults)}
          onChange={handleChange}
          onBlur={() =>
            setAdultsWarnings(getAdultWarnings(state.adults as number))
          }
          value={state.adults}
          errorMessage={adultsError(cyclesErrors, cycle.cycle_number)}
        />
      </Warning>

      <Warning
        warningTexts={childrenWarnings}
        maxWidth={250}
        dismissible
        onDismiss={() => setChildrenWarnings([])}
      >
        <Input
          min="0"
          fluid
          required
          type="number"
          name="children"
          label={i18n.ft(messages.children)}
          onChange={handleChange}
          onBlur={() =>
            setChildrenWarnings(getChildrenWarnings(state.children as number))
          }
          value={state.children}
          errorMessage={childrenError(cyclesErrors, cycle.cycle_number)}
        />
      </Warning>
    </>
  );

  function isSectionWithErrors() {
    if (!cyclesErrors) {
      return false;
    }

    const cycleErrors = cyclesErrors[cycle.cycle_number];
    if (!cycleErrors) {
      return false;
    }

    return cycleErrors.metaDataHasErrors;
  }

  return (
    <SectionCollapse
      attached
      className={className}
      title={i18n.ft(messages.details)}
      subtitle={i18n.ft(messages.sectionDescription)}
      status={isSectionWithErrors() ? 'error' : null}
      closeOthersOnOpen
    >
      <div className="sm:px-14 sm:pb-5">
        <FormRow>
          <Warning
            warningTexts={cycleTimeWarnings}
            maxWidth={290}
            dismissible
            onDismiss={() => setCycleTimeWarnings([])}
          >
            <TimeRangeInput
              required
              label={i18n.ft(messages.cycleTime)}
              value={[state.start_time, state.end_time]}
              onChange={handleTimeChange}
              errorMessage={cycleTimeErrorMessage()}
              onBlur={validateTime}
            />
          </Warning>

          {!hasNumericFields && <>{commonFields()}</>}
        </FormRow>

        {hasNumericFields && (
          <>
            <FormColumn>{commonFields()}</FormColumn>

            {groupedNumericFields.map((fieldsRow, index) => {
              const [firstField] = fieldsRow;
              return (
                <React.Fragment key={`numeric_fields_row_${index}`}>
                  <FormColumn>
                    {fieldsRow.map((field, fieldIndex) => (
                      <MetaDataFields
                        key={`numeric_field_${fieldIndex}`}
                        fields={field}
                        defaultValue={valueForField(field.id, cycle)}
                        assessment={assessment}
                        cycle={cycle}
                        onChange={value =>
                          setNumericFieldsState({
                            ...numericFieldsState,
                            [field.t_name]: value
                          })
                        }
                      />
                    ))}
                  </FormColumn>
                  <Warning
                    warningTexts={numericFieldsWarnings(
                      numericFieldsState,
                      state,
                      firstField
                    )}
                    floating={false}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}

        {isClassFirstEdition && (
          <Box mt="50px">
            <CategoriesGrid>
              {hasSelectOneFields &&
                selectOneFields.map(category => (
                  <React.Fragment key={category.id}>
                    <MetaDataSelectOne
                      observationId={assessment.observation!.id}
                      cycle={cycle}
                      category={category}
                      values={cycle.cycle_meta_data_values}
                    />
                  </React.Fragment>
                ))}
            </CategoriesGrid>
            <CategoriesGrid>
              {categoriesClassFirstEdition.map((category, idx) => (
                <React.Fragment key={category.id}>
                  {idx !== 0 && <VerticalSeparator />}
                  <MetaDataMultiSelect
                    observationId={assessment.observation!.id}
                    cycle={cycle}
                    category={category}
                    values={cycle.cycle_meta_data_values}
                  />
                </React.Fragment>
              ))}
            </CategoriesGrid>
          </Box>
        )}

        {isClassSecondEdition && (
          <div className="mt-16">
            <MetaDataCategoryForm
              categories={categoriesClassSecondEdition}
              cycle={cycle}
              observationId={assessment.observation!.id}
            />
          </div>
        )}
      </div>
    </SectionCollapse>
  );
}

export default CycleDetailsForm;

import { isNil } from 'ramda';
import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  DateInput as BaseDateInput,
  DateInputProps as BaseProps
} from 'semantic-ui-calendar-react';

interface DateInputProps extends BaseFieldProps<Partial<BaseProps>> {}

function DateInput({
  name,
  validate,
  className,
  mask,
  errorLabel = {},
  ...props
}: DateInputProps) {
  const currentUser = useCurrentUser();

  const [field, meta, helpers] = useField({
    name,
    validate
  });

  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = field.value || '';

  const handleChange: BaseProps['onChange'] = (event, data) => {
    if (mask && data.value.match(mask)) {
      data.value = data.value.replace(/[/. ]/g, '-');
    }
    helpers.setValue(data.value);
    if (props.onChange) {
      props.onChange(event, data);
    }
  };

  const handleBlur: BaseProps['onBlur'] = () => {
    helpers.setTouched(true);
  };

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={defaultValue}
        error={isError}
        onChange={handleChange}
        onBlur={handleBlur}
        aria-label={props['aria-label'] || props.label}
        localization={
          currentUser && !isNil(currentUser.preferred_language)
            ? currentUser.preferred_language
            : 'en'
        }
      />
      <ErrorLabel active={isError} content={meta.error} {...errorLabel} />
    </FormRowWrapper>
  );
}

DateInput.defaultProps = {
  control: BaseDateInput,
  closeOnMouseLeave: false,
  hideMobileKeyboard: true,
  dateFormat: 'MM/DD/YYYY',
  iconPosition: 'left',
  animation: 'none',
  duration: 0,
  closable: true,
  pickerStyle: { minWidth: '320px', maxWidth: '400px' }
};

export default DateInput;

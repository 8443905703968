import { useContext } from 'react';
import { HierarchyContext } from './HierarchyContext';

export function useHierarchy() {
  const context = useContext(HierarchyContext);

  if (!context) {
    throw new Error('useHierarchy must be used within a <HierarchyProvider />');
  }

  return context;
}

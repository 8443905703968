import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export function getGrades(params = {}) {
  return axios.get('/api/v2/grades', { params });
}

export function useGetGrades() {
  return useQuery({
    queryKey: ['grades'],
    queryFn: () => getGrades().then(response => response.data)
  });
}

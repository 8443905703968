import { clsx } from 'clsx';

import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';

type OptionValue = string | number | null | undefined;

type BaseSelectProps = {
  /** List of options for the select dropdown. */
  options: SelectOption[];

  /** Optional field label. */
  label?: string;

  /** Optional text for placeholder content. */
  placeholder?: string;

  /** When `true`, label indicates field is required. */
  required?: boolean;
};

type SingleSelectProps = BaseSelectProps & {
  /** Current value of the select. */
  value: OptionValue;

  /** Callback fired when the value changes. */
  onChange(value: OptionValue | null): void;

  /** When `false`, the select component is for one option only. */
  multiple?: false | undefined;
};

type MultiSelectProps = BaseSelectProps & {
  /** Current value of the select. */
  value: OptionValue[];

  /** Callback fired when the value changes. */
  onChange(value: OptionValue[]): void;

  /** When `true`, the select component is for multiple options. */
  multiple: true;
};

export type SelectOption = {
  value: OptionValue;
  text: string;
};

export type SelectProps = SingleSelectProps | MultiSelectProps;

function getSelected(
  multiple: boolean | undefined,
  options: SelectOption[],
  value: OptionValue | OptionValue[]
) {
  if (multiple) {
    return options.filter(option =>
      (value as OptionValue[]).includes(option.value)
    );
  }

  return options.find(option => option.value === value) || null;
}

export function Select({
  multiple,
  value,
  placeholder,
  options,
  onChange,
  label,
  required
}: SelectProps) {
  const selected = getSelected(multiple, options, value);
  const isClearable = !multiple && selected !== null;

  const classes = clsx(
    'font-sans text-black text-left flex items-center gap-2 justify-between p-2 bg-white border border-[#889EBB] rounded-lg w-full',
    // focus state
    'data-[focus]:outline-0 data-[focus]:border-[#0A9CCA] data-[focus]:ring-1 data-[focus]:ring-[#0A9CCA]',
    // open state
    'data-[open]:rounded-b-none data-[open]:outline-0 data-[open]:border-b-2 data-[open]:border-[#0A9CCA] data-[open]:ring-1 data-[open]:ring-[#0A9CCA]'
  );

  const handleChange = (option: SelectOption | SelectOption[] | null) => {
    if (multiple) {
      onChange((option as SelectOption[]).map(o => o.value));
    } else {
      onChange((option as SelectOption)?.value || null);
    }
  };

  const handleClear = (event: React.MouseEvent) => {
    if (multiple || selected === null) {
      return;
    }

    event.preventDefault();
    onChange(null);
  };

  const handleKeyDownClear = (event: React.KeyboardEvent) => {
    if (multiple || selected === null) {
      return;
    }

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(null);
    }
  };

  const handleTagRemove = (event: React.MouseEvent, option: OptionValue) => {
    if (!multiple) {
      return;
    }

    event.stopPropagation();
    onChange(value.filter(v => v !== option));
  };

  const getButtonPlaceholder = () => {
    if (!multiple) {
      return (selected as SelectOption)?.text || placeholder || '';
    }

    if (selected === null) {
      return placeholder || '';
    }

    return (
      <div className="flex flex-wrap gap-1.5">
        {(selected as SelectOption[]).map(option => (
          <span
            key={option.value}
            className="flex items-center gap-2 pl-1.5 pr-1 py-0.5 bg-[#F0E9EF] rounded border border-[#889EBB]"
          >
            <span className="text-sm font-semibold">{option.text}</span>

            <span
              tabIndex={0}
              role="button"
              onClick={event => handleTagRemove(event, option.value)}
              className="w-5 h-5 flex justify-center items-center"
            >
              <i className="fa-solid fa-times" />
            </span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <Field className="flex flex-col gap-1 font-sans">
      {label && (
        <Label className="font-semibold text-base text-black">
          {label + (required ? '*' : '')}
        </Label>
      )}

      <Listbox
        by="value"
        value={selected}
        onChange={handleChange}
        multiple={multiple}
      >
        <ListboxButton className={classes}>
          <span
            className={clsx(
              'truncate text-base',
              !selected && 'text-[#3C3F42]'
            )}
          >
            {getButtonPlaceholder()}
          </span>

          <span
            onClick={handleClear}
            onKeyDown={handleKeyDownClear}
            tabIndex={isClearable ? 0 : -1}
            className="p-1 flex items-center"
          >
            {isClearable ? (
              <i className="fa-solid fa-xmark" />
            ) : (
              <i className="fa-solid fa-caret-down" />
            )}
          </span>
        </ListboxButton>

        <ListboxOptions
          anchor="bottom start"
          className="w-[var(--button-width)] bg-white border-x border-b border-[#0A9CCA] ring-1 ring-[#0A9CCA] rounded-b-lg !max-h-40 outline-none empty:invisible overflow-auto"
        >
          {options.map((option, idx) => (
            <ListboxOption
              key={option.value}
              value={option}
              className={clsx(
                'font-sans text-black p-2 data-[focus]:bg-[#F7F8F9] data-[selected]:bg-action-xlight',
                idx === options.length - 1 && 'rounded-b-lg'
              )}
            >
              {option.text}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </Field>
  );
}

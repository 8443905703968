import i18n from 'helpers/i18n';
import messages from './messages';
import fbIcon from 'images/footer/FB.png';
import twIcon from 'images/footer/Twitter.png';
import linIcon from 'images/footer/Linkedin.png';
import pinIcon from 'images/footer/Pinterest.png';
import newLogo from 'images/footer/TS_New_Brand.png';

function Footer() {
  const classLinks =
    'text-[#28343A] font-medium hover:underline decoration-solid hover:text-[#28343A]';

  return (
    <footer className="bg-[#EBEBEB] mt-8">
      <div className="flex flex-col md:flex-row gap-4 py-8 px-4 justify-around">
        <div className="w-44 pl-4">
          <a href="/" aria-label="Teachstone">
            <img src={newLogo} alt="Teachstone" className="scale-100" />
          </a>
        </div>
        <div className="flex-col gap-2 pl-3 md:pl-0">
          <div className="mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/privacy-policy"
              className={classLinks}
            >
              {i18n.ft(messages.privacy)}
            </a>
          </div>
          <div className="mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/terms-conditions"
              className={classLinks}
            >
              {i18n.ft(messages.terms)}
            </a>
          </div>
          <div className="mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/web-accessibility"
              className={classLinks}
            >
              {i18n.ft(messages.accessibility)}
            </a>
          </div>
          <div className="mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/support"
              className={classLinks}
            >
              {i18n.ft(messages.support)}
            </a>
          </div>
        </div>
        <div className="flex">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/Teachstone"
          >
            <img className="w-16" alt="Facebook" src={fbIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/teachstone"
          >
            <img className="w-16" alt="Linkedin" src={linIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/Teachstone"
          >
            <img className="w-16" alt="Twitter" src={twIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pinterest.com/teachstone/pins"
          >
            <img className="w-16" alt="Pinterest" src={pinIcon} />
          </a>
        </div>
      </div>
      <div className="mb-1 flex justify-center text-[#28343A]">
        {i18n.ft(messages.copyright, { year: new Date().getFullYear() })}
      </div>
    </footer>
  );
}

export default Footer;

import { NodeContext } from './NodeContext';
import { useHierarchy } from './useHierarchy';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

interface NodeProviderProps {
  accountId: number;
  children: React.ReactNode;
  depth: number;
  node: AccountHierarchy;
  as: React.ComponentType<any>;
}

export function NodeProvider({ children, ...props }: NodeProviderProps) {
  const { openNodeIds, setOpenNodeIds } = useHierarchy();
  const isOpen = openNodeIds.includes(props.node.id);

  const setIsOpen = (open: boolean) => {
    if (open) {
      setOpenNodeIds([...openNodeIds, props.node.id]);
    } else {
      setOpenNodeIds(openNodeIds.filter(id => id !== props.node.id));
    }
  };

  return (
    <NodeContext.Provider value={{ isOpen, setIsOpen, ...props }}>
      {children}
    </NodeContext.Provider>
  );
}

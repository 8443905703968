import axios from 'axios';

type EducatorSettingsResponse = {
  language: string;
  age_levels: string[];
  learning_resources_menu?: string;
  organization_hierarchy_id?: number;
};

export function getEducatorSettings() {
  return axios.get('/api/v2/educator_settings');
}

export function updateEducatorSettings(data: EducatorSettingsResponse) {
  return axios.put('/api/v2/educator_settings', { settings: { ...data } });
}

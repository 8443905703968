import i18n from 'helpers/i18n';
import messages from './messages';
import { Loader } from 'semantic-ui-react';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import VideoResource from '../VideoResource';
import { DEFAULT_PER_PAGE } from 'pages/LearningResources/utils';
import {
  getHoursMinutes,
  getResourceRoute
} from 'pages/LearningResources/utils';
import {
  LearningResourceSearchResult,
  ResourceType
} from 'types/api/LearningResource';

// Actions
import { getEducatorRecommendations } from 'actions/coachings';
import { completedRecommended } from 'actions/recommendations';
import { getClassroomScores } from 'actions/learningResources';
import { getMyResources } from 'actions/learningResourcesMyResources';
import { getRecommendedLearningResources } from 'actions/learningResources';
import { getGettingStarted } from 'actions/learningResourcesGettingStarted';
import { getViewingHistory } from 'actions/learningResourcesViewingHystory';
import { getAllStarred } from 'actions/starred';
import { getLastViewedLearningResources } from 'actions/learningResources';

interface CoachSectionProps {
  action: string;
  educatorId?: number;
  educatorGuid?: string;
  educatorName?: string;
  ageLevel?: string;
  showTitle?: boolean;
}

function ResourcesSection({
  action,
  educatorId,
  educatorGuid,
  educatorName,
  ageLevel,
  showTitle = true
}: CoachSectionProps) {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [resources, setResources] = useState<LearningResourceSearchResult[]>(
    []
  );

  // Pagination
  const [loadMoreButtonState, setLoadMoreButtonState] = useState('hide');
  const loadMoreRef = useRef<HTMLButtonElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [
    completedRecommendedResourcesCurrentPage,
    setCompletedRecommendedResourcesCurrentPage
  ] = useState(1);
  const [recommendedResourcesCurrentPage, setRecommendedResourcesCurrentPage] =
    useState(1);

  // Completed Recommended
  const initializeCompletedRecommended = useCallback(
    () => {
      setTitle(
        i18n.ft(messages.completedRecommended, { educator: educatorName })
      );
      completedRecommended(
        educatorId,
        DEFAULT_PER_PAGE,
        completedRecommendedResourcesCurrentPage
      )
        .then((res: any) => {
          completedRecommendedResourcesCurrentPage === 1
            ? setResources(res.data.learning_resources)
            : setResources([...resources, ...res.data.learning_resources]);
          setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [educatorId, educatorName, completedRecommendedResourcesCurrentPage]
  );

  // Recommended Resources
  const initializeRecommendedResources = useCallback(
    () => {
      setTitle(i18n.ft(messages.recommendations, { educator: educatorName }));
      getEducatorRecommendations(
        educatorGuid,
        DEFAULT_PER_PAGE,
        recommendedResourcesCurrentPage
      )
        .then((res: any) => {
          recommendedResourcesCurrentPage === 1
            ? setResources([...res.data.learning_resources])
            : setResources([...resources, ...res.data.learning_resources]);
          setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [educatorGuid, educatorName, recommendedResourcesCurrentPage]
  );

  // My Resources
  const initializeMyResources = useCallback(() => {
    setTitle(i18n.ft(messages.myResources));

    getMyResources(DEFAULT_PER_PAGE, currentPage)
      .then((res: any) => {
        setResources([...resources, ...res.data.resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // My Recommendations
  const initializeMyRecommendations = useCallback(() => {
    setTitle(i18n.ft(messages.recommendedTo));
    getRecommendedLearningResources({
      per_page: DEFAULT_PER_PAGE,
      page: currentPage
    })
      .then((res: any) => {
        const resourceables = res.data.recommendations.map(
          (resource: any) => resource.resourceable
        );
        setResources([...resources, ...resourceables]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Getting Started with Learning Resources
  const initializeGettingStarted = useCallback(() => {
    setTitle(i18n.ft(messages.gettingStarted));
    getGettingStarted(DEFAULT_PER_PAGE, currentPage)
      .then((res: any) => {
        setResources([...resources, ...res.data.resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Important Resources
  const initializeImportant = useCallback(() => {
    setTitle(i18n.ft(messages.important));
    getAllStarred(DEFAULT_PER_PAGE, currentPage)
      .then((res: any) => {
        setResources([...resources, ...res.data.learning_resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Based on My Classroom Scores
  const initializeClassroomScores = useCallback(() => {
    setTitle(i18n.ft(messages.classroomScores, { educator: educatorName }));
    getClassroomScores(educatorId, ageLevel, currentPage, DEFAULT_PER_PAGE)
      .then((res: any) => {
        setResources([...resources, ...res.data.resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {
        setLoadMoreButtonState('hide');
      })
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educatorId, ageLevel, educatorName, currentPage]);

  // Based on My Viewing History
  const initializeViewingHistory = useCallback(() => {
    setTitle(i18n.ft(messages.viewingHistory));
    getViewingHistory(DEFAULT_PER_PAGE, currentPage)
      .then((res: any) => {
        setResources([...resources, ...res.data.resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Last Viewed
  const initializeLastViewed = useCallback(() => {
    setTitle(i18n.ft(messages.viewingHistory));
    getLastViewedLearningResources(DEFAULT_PER_PAGE, currentPage)
      .then((res: any) => {
        setResources([...resources, ...res.data.learning_resources]);
        setLoadMoreButtonState(getLoadMoreButtonState(res.data.pagination));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const returnSelectedAction = useCallback(() => {
    switch (action) {
      case 'completedRecommended':
        initializeCompletedRecommended();
        break;
      case 'recommendedResources':
        initializeRecommendedResources();
        break;
      case 'classroomScores':
        initializeClassroomScores();
        break;
      case 'myResources':
        initializeMyResources();
        break;
      case 'recommended':
        initializeMyRecommendations();
        break;
      case 'gettingStarted':
        initializeGettingStarted();
        break;
      case 'viewingHistory':
        initializeViewingHistory();
        break;
      case 'important':
        initializeImportant();
        break;
      case 'lastViewed':
        initializeLastViewed();
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  }, [
    action,
    initializeCompletedRecommended,
    initializeRecommendedResources,
    initializeClassroomScores,
    initializeMyResources,
    initializeMyRecommendations,
    initializeGettingStarted,
    initializeViewingHistory,
    initializeImportant,
    initializeLastViewed
  ]);

  useEffect(() => {
    returnSelectedAction();
  }, [returnSelectedAction]);

  useEffect(() => {
    setCurrentPage(1);
    setCompletedRecommendedResourcesCurrentPage(1);
    setRecommendedResourcesCurrentPage(1);
  }, [educatorGuid]);

  const handleClick = useCallback(
    (id: number, resourceType: ResourceType) =>
      navigate(
        getResourceRoute(id, resourceType, user.roles.includes('lr_coach'))
      ),
    [navigate, user]
  );

  function loadMore() {
    setIsFetching(true);
    setCurrentPage(prevPageNo => prevPageNo + 1);
    switch (action) {
      case 'recommendedResources':
        setRecommendedResourcesCurrentPage(prevPageNo => prevPageNo + 1);
        break;
      case 'completedRecommended':
        setCompletedRecommendedResourcesCurrentPage(
          prevPageNo => prevPageNo + 1
        );
        break;
      default:
        break;
    }
  }

  function getLoadMoreButtonState(pagination: {
    total_pages: number;
    current_page: number;
  }) {
    if (pagination.total_pages <= 1) {
      return 'hide';
    }

    return pagination.total_pages === pagination.current_page
      ? 'disabled'
      : 'enabled';
  }

  function renderShowMoreButton() {
    if (loadMoreButtonState === 'hide') {
      return;
    }

    return (
      <div className="flex justify-center mt-8 mb-10">
        {isFetching ? (
          <Loader active inline="centered" />
        ) : (
          <button
            ref={loadMoreRef}
            className={`${
              loadMoreButtonState === 'disabled'
                ? 'bg-[#98d0e1]'
                : 'bg-[#0A9CCA]'
            } text-white font-bold py-2 px-4 rounded focus:shadow-outline`}
            onClick={loadMore}
            disabled={loadMoreButtonState === 'disabled'}
          >
            {i18n.ft(messages.loadMore)}
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="mb-10">
      {showTitle && (
        <div className="block md:flex items-center mb-2">
          <h2 className="text-black">{title}</h2>
        </div>
      )}

      {loading ? (
        <div className="mb-10">
          <Loader active inline="centered" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {resources?.length === 0 ? (
              <div className="w-full mx-auto" aria-live="polite">
                {i18n.ft(messages.noResults)}
              </div>
            ) : (
              resources?.map(
                (video: LearningResourceSearchResult, idx: number) => (
                  <div key={idx}>
                    <VideoResource
                      ariaLabel={'Video Thumbnail'}
                      ariaRoleDescription={'Slide'}
                      handleClick={handleClick}
                      video={{
                        name: video.name,
                        id: video.id ? video.id : idx,
                        videoURI: video.video_uri
                          ? video.video_uri
                          : '1_bkuk1xi7',
                        estimatedTime: video.estimated_time
                          ? getHoursMinutes(video.estimated_time)
                          : '0'
                      }}
                      tags={video.tags}
                      type={video.type}
                    />
                  </div>
                )
              )
            )}
          </div>

          {renderShowMoreButton()}
        </>
      )}
    </div>
  );
}

export default ResourcesSection;

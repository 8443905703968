import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Input } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';
import ResourcesSection from 'components/LearningResources/ResourcesSection';
import SearchResults from 'components/LearningResources/SearchResults';
import { isEmpty } from 'ramda';

function EducatorViewMode() {
  const currentUser = useCurrentUser();
  const [query, setQuery] = useState('');

  function isCoach() {
    return currentUser.roles.includes('lr_coach');
  }

  return (
    <>
      {isCoach() && (
        <div className="w-full mt-4 p-4 bg-[#cbe3ed] rounded">
          <div className="flex justify-between">
            <div>{i18n.ft(messages.educatorView)}</div>
            <div className="font-bold text-[#0A9CCA] cursor-pointer">
              <a href={RouteHelpers.getPath('coach-learning-resources')}>
                {`< ${i18n.ft(messages.back)}`}
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="mt-8 mb-16 px-4 md:px-0 md:py-8">
        <div className="w-full ">
          <Input
            fluid
            id="search"
            value={query}
            className="mb-8 max-w-[856px] text-center mx-auto"
            onChange={(e, data) => setQuery(data.value)}
            placeholder={i18n.ft(messages.searchPlaceholder)}
            icon={
              isEmpty(query)
                ? 'search'
                : { link: true, name: 'times', onClick: () => setQuery('') }
            }
          />
        </div>

        {query === '' ? (
          <>
            <ResourcesSection action={'recommended'} />
            <ResourcesSection action={'lastViewed'} />
            <ResourcesSection action={'important'} />
          </>
        ) : (
          <SearchResults query={query} />
        )}
      </div>
    </>
  );
}

export default EducatorViewMode;

import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { useNavigate } from 'react-router-dom';
import { useLanguages } from 'components/dropdowns/Languages';
import { FormDropdown, Dropdown } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { toastError } from 'components/Toast';

import {
  getEducatorSettings,
  updateEducatorSettings
} from 'actions/educatorSettings';
import useAsync from 'hooks/useAsync';
import { EducatorSettings, HierarchySettings } from './types';

function PreferencesPage() {
  const navigate = useNavigate();
  const languagesOptions = useLanguages();
  const { run, isPending } = useAsync();
  const [educatorSettings, setEducatorSettings] = useState<EducatorSettings>({
    language: '',
    age_levels: {}
  });
  const [ageLevels, setAgeLevels] = useState<string[]>([]);
  const [organizationHierarchy, setOrganizationHierarchy] = useState<
    HierarchySettings[]
  >([]);

  useEffect(() => {
    run(getEducatorSettings(), {
      onSuccess: data => {
        setEducatorSettings(data);
        let ageLevels = Object.keys(data.age_levels).filter(
          key => data.age_levels[key]
        );
        setAgeLevels(ageLevels);
        setOrganizationHierarchy(data.organization_hierarchies);
      },
      onError: () => {
        toastError({ description: i18n.ft(messages.error) });
      }
    });
  }, [run]);

  function formatHierarchies(hierarchy: HierarchySettings[]) {
    return hierarchy.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id
    }));
  }

  function handleOnChange(value: string) {
    updateEducatorSettings({
      age_levels: [...ageLevels],
      language: value.trim(),
      organization_hierarchy_id: educatorSettings.organization_hierarchy_id
    });
    setEducatorSettings({
      ...educatorSettings,
      language: value.trim()
    });
  }

  function handleOnClick(key: string, value: boolean) {
    let newAgeLevels = [...ageLevels];

    if (value) {
      newAgeLevels.push(key);
    } else {
      newAgeLevels = newAgeLevels.filter(age => age !== key);
    }

    setAgeLevels(newAgeLevels);
    updateEducatorSettings({
      language: educatorSettings.language,
      age_levels: [...newAgeLevels],
      organization_hierarchy_id: educatorSettings.organization_hierarchy_id
    });

    setEducatorSettings({
      ...educatorSettings,
      age_levels: { ...educatorSettings.age_levels, [key]: value }
    });
  }

  function handleHierarchyOnChange(value: any) {
    updateEducatorSettings({
      age_levels: [...ageLevels],
      language: educatorSettings.language,
      organization_hierarchy_id: value
    });
    setEducatorSettings({
      ...educatorSettings,
      organization_hierarchies: organizationHierarchy.map(hierarchy => ({
        ...hierarchy,
        selected: hierarchy.id === value
      }))
    });
  }

  if (isPending) {
    return null;
  }

  return (
    <div>
      <div className="pt-4 text-[#0A9CCA] font-bold">
        <span
          className="cursor-pointer"
          onClick={() => navigate(RouteHelpers.getPath('learning-resources'))}
        >{`< ${i18n.ft(messages.back)}`}</span>
      </div>
      <div className="text-2xl text-black font-bold w-full mt-8">
        {i18n.ft(messages.learner)}
        <div className="text-[16px] text-black font-normal">
          {i18n.ft(messages.choose)}
        </div>
      </div>

      <div className="text-black mt-8">
        <label className="font-bold text-normal" htmlFor="language_id">
          {i18n.ft(messages.language)}
        </label>
        <FormDropdown
          name="language_id"
          id={'language_id'}
          options={languagesOptions}
          className="rounded-md mt-2"
          selection
          value={
            languagesOptions.find(
              language => language.text === educatorSettings.language
            )?.value || ''
          }
          onChange={e => {
            const target = e.target as HTMLSelectElement;
            handleOnChange(target.innerText);
          }}
        />
      </div>

      <div className="text-black mt-8">
        <label className="font-bold text-normal">{i18n.ft(messages.age)}</label>
        <div className="flex flex-wrap">
          {Object.entries(educatorSettings.age_levels).map(([key, value]) => (
            <div
              key={key}
              className={`mt-2 border border-black rounded-md p-3 mr-3 cursor-pointer ${value ? 'bg-black text-white' : 'bg-{#f3f4f6} text-black'}`}
              onClick={() => handleOnClick(key, !value as boolean)}
            >
              {key}
            </div>
          ))}
        </div>
      </div>

      {organizationHierarchy.length > 0 && (
        <div className="text-black mt-8">
          <div>
            <label
              className="font-bold text-normal"
              htmlFor="organization_hierarchy_id"
            >
              {i18n.ft(messages.hierarchy)}
            </label>
          </div>
          <Dropdown
            selection
            className="mt-2"
            selectOnBlur={false}
            value={
              educatorSettings.organization_hierarchies?.find(
                hierarchy => hierarchy.selected
              )?.id || ''
            }
            onChange={(_, { value }) => handleHierarchyOnChange(value)}
            options={formatHierarchies(
              educatorSettings.organization_hierarchies ?? []
            )}
          />
        </div>
      )}
    </div>
  );
}

export default PreferencesPage;

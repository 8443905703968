import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { IconButton } from 'components/Organization/IconButton';
import {
  createAccountNode,
  deleteAccountNode,
  updateAccountNode
} from 'actions/accounts';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import { DeleteNodeModal } from 'components/Organization/DeleteNodeModal';

import {
  useNode,
  Node,
  NodeName,
  NodeChildren,
  NodeDisclosureButton
} from 'components/Organization/HierarchyBuilder';
import NodeForm from './NodeForm';

export function EditableNode() {
  const { depth, node, accountId, setIsOpen } = useNode();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const [nodeType, setNodeType] = useState('GenericNode');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleEditNode = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsEditing(true);
  };

  const handleRemoveNode = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsDeleting(true);
  };

  const handleDeleteNodeConfirm = async () => {
    await deleteAccountNode(accountId, node.id);
    setIsDeleting(false);
    toastSuccess({
      message: i18n.ft(messages.nodeRemoved, { nodeName: node.name })
    });

    queryClient.invalidateQueries({
      queryKey: ['accounts', accountId, 'hierarchy']
    });
  };

  const handleAddNode = (event: React.SyntheticEvent, nodeType: string) => {
    event.stopPropagation();
    setNodeType(nodeType);
    setIsAdding(true);
  };

  const handleSaveNode = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = event.currentTarget.elements as any;
    const nodeName = formData.name.value;

    if (!nodeName) {
      return;
    }

    try {
      await createAccountNode(accountId, {
        node: {
          names: [nodeName],
          type: nodeType,
          parent_id: node.id
        }
      });

      setIsOpen(true);
      setIsAdding(false);
      toastSuccess({
        message: i18n.ft(messages.nodeAdded, {
          nodeName
        })
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts', accountId, 'hierarchy']
      });
    } catch {
      // TODO: Connect to API error after API is updated
      toastError({
        message: i18n.ft(messages.nodeError, {
          nodeName
        })
      });
    }
  };

  function getAddNodeType() {
    if (node.children.length === 0) {
      return 'GenericNode';
    }

    const firstChild = node.children[0];
    return firstChild.type;
  }

  function getAddNodeLabel() {
    if (node.children.length === 0) {
      return i18n.ft(messages.addCenter);
    }

    const firstChild = node.children[0];
    return firstChild.type === 'GenericNode'
      ? i18n.ft(messages.addCenter)
      : i18n.ft(messages.addClassroom);
  }

  const handleFieldSave = async (
    event: React.FormEvent<HTMLFormElement>,
    value: any
  ) => {
    event.preventDefault();
    if (!value) {
      return;
    }

    try {
      await updateAccountNode(accountId, node.id, {
        node: { ...node, name: value }
      });

      setIsOpen(true);
      setIsEditing(false);
      toastSuccess({
        message: i18n.ft(messages.nodeEdited, {
          nodeName: value
        })
      });

      queryClient.invalidateQueries({
        queryKey: ['accounts', accountId, 'hierarchy']
      });
    } catch {
      toastError({
        message: i18n.ft(messages.nodeEditError, {
          nodeName: value
        })
      });
    }
  };

  function getRemoveNodeLabel() {
    return node.type === 'GenericNode'
      ? i18n.ft(messages.removeCenter)
      : i18n.ft(messages.removeClassroom);
  }

  function getNodeTypeName() {
    return node.type === 'GenericNode'
      ? i18n.ft(messages.center)
      : i18n.ft(messages.classroom);
  }

  return (
    <>
      <DeleteNodeModal
        open={isDeleting}
        nodeTypeName={getNodeTypeName()}
        onConfirm={handleDeleteNodeConfirm}
        onClose={() => setIsDeleting(false)}
      />

      {isEditing ? (
        <NodeForm
          depth={depth}
          setIsAvailable={setIsEditing}
          type="edit"
          onSave={handleFieldSave}
          value={node.name}
        />
      ) : (
        <Node className="group">
          <div className="flex items-center gap-3">
            {depth > 1 && <i className="fa-solid fa-arrow-turn-down-right" />}
            <NodeName />
          </div>

          <div className="flex items-center gap-8">
            <div className="items-center gap-4 hidden group-focus-within:flex group-focus:flex group-hover:flex">
              <IconButton
                label={i18n.ft(messages.editName)}
                icon="fa-solid fa-pencil"
                onClick={handleEditNode}
              />

              {node.type === 'GenericNode' && (
                <IconButton
                  label={getAddNodeLabel()}
                  icon="fa-solid fa-plus"
                  onClick={event => handleAddNode(event, getAddNodeType())}
                />
              )}

              {node.type === 'GenericNode' && node.children.length === 0 && (
                <IconButton
                  label={i18n.ft(messages.addClassroom)}
                  icon="fa-solid fa-school"
                  onClick={event => handleAddNode(event, 'Classroom')}
                />
              )}

              {node.children.length === 0 && (
                <IconButton
                  label={getRemoveNodeLabel()}
                  icon="fa-solid fa-trash-can"
                  onClick={handleRemoveNode}
                />
              )}
            </div>

            <NodeDisclosureButton />
          </div>
        </Node>
      )}

      {isAdding && (
        <NodeForm
          depth={depth}
          setIsAvailable={setIsAdding}
          type="add"
          onSave={handleSaveNode}
        />
      )}

      <NodeChildren />
    </>
  );
}

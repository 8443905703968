const PATH = 'fe.components.training_history.training_card';

const messages = {
  hours: {
    key: `${PATH}.hours`,
    defaultValue: 'Learning Hours Earned'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs Earned'
  },
  started: {
    key: `${PATH}.started`,
    defaultValue: 'Started'
  },
  ended: {
    key: `${PATH}.ended`,
    defaultValue: 'Ended'
  },
  certificate: {
    key: `${PATH}.certificate`,
    defaultValue: 'Download Certificate'
  },
  downloading: {
    key: `${PATH}.downloading`,
    defaultValue: 'Downloading...'
  },
  onlineCourse: {
    key: `${PATH}.online_course`,
    defaultValue: 'Online Coursework'
  },
  learningPathway: {
    key: `${PATH}.learning_pathway`,
    defaultValue: 'Learning Pathway'
  },
  facilitatedTraining: {
    key: `${PATH}.facilitated_training`,
    defaultValue: 'Facilitated Training'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue: 'Complete'
  }
};

export default messages;

const PATH = 'fe.components.training_history.training_summary';

const messages = {
  hours: {
    key: `${PATH}.hours`,
    defaultValue: 'Learning Hours Earned'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs Earned'
  },
  pdAccomplishments: {
    key: `${PATH}.pd_accomplishments`,
    defaultValue: 'Your PD Accomplishments'
  }
};

export default messages;

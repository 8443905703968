const PATH = 'fe.pages.learning_resources.all_resources_page';

const messages = {
  more: {
    key: `${PATH}.more`,
    defaultValue: 'Load More'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'All'
  },
  watched: {
    key: `${PATH}.watched`,
    defaultValue: 'Watched'
  },
  new: {
    key: `${PATH}.new`,
    defaultValue: 'New to You'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No results found'
  },
  allResources: {
    key: `${PATH}.all_resources`,
    defaultValue: 'Search all resources'
  }
};

export default messages;

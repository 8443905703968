import i18n from 'helpers/i18n';
import { isEmpty } from 'ramda';
import messages from './messages';
import { getHoursMinutes, getResourceRoute } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Loader, Input } from 'semantic-ui-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  LearningResourceSearchResult,
  ResourceType
} from 'types/api/LearningResource';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { Formik } from 'components/EnhancedForm';
import VideoResource from 'components/LearningResources/VideoResource';
import SortDropdown from 'components/LearningResources/SortDropdown';
import { getLearningResources } from 'actions/learningResources';
import useCurrentUser from 'hooks/useCurrentUser';
import SearchResults from 'components/LearningResources/SearchResults';

function AllResourcesPage() {
  const DEFAULT_PER_PAGE = 20;
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const loadMoreRef = useRef<HTMLButtonElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const selectedCategory = 'all';
  const [learningResources, setLearningResources] = useState<
    LearningResourceSearchResult[]
  >([]);
  const { state, setState } = usePaginatedSearch({
    page: currentPage,
    query: '',
    sort_by: '',
    sort_dir: '',
    per_page: DEFAULT_PER_PAGE
  });

  useEffect(() => {
    getResources(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, state]);

  const handleClick = useCallback(
    (id: number, resourceType: ResourceType) =>
      navigate(
        getResourceRoute(id, resourceType, user.roles.includes('lr_coach'))
      ),
    [navigate, user]
  );

  function loadMore() {
    setIsFetching(true);
    getResources();
    setPerPage(perPage + DEFAULT_PER_PAGE);
  }

  function handleSort(value: string) {
    const direction = value === 'name' ? 'asc' : 'desc';
    setState({
      ...state,
      sort_by: value,
      sort_dir: direction,
      per_page: perPage
    });
  }

  function getResources(refresh = false) {
    const page = refresh ? 1 : currentPage + 1;

    getLearningResources({ ...state, page: page })
      .then(res => {
        if (!res.data.learning_resources) {
          return;
        }
        res.data.learning_resources.length === 0 ||
        res.data.pagination.total_pages === res.data.pagination.current_page
          ? setDisableLoadMore(true)
          : setDisableLoadMore(false);
        setCurrentPage(prevPageNo => prevPageNo + 1);
        refresh
          ? setLearningResources(res.data.learning_resources)
          : setLearningResources([
              ...learningResources,
              ...res.data.learning_resources
            ]);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
        if (refresh) {
          setCurrentPage(1);
        }
      });
  }

  if (!learningResources) {
    return <Loader active />;
  }

  return (
    <div className="mt-8 mb-16 px-4 md:px-0" style={{ overflowAnchor: 'auto' }}>
      <div className="sm:justify-center gap-2 clear-both">
        <div className="flex mb-6 flex-wrap md:justify-between">
          <div className="pb-3">
            <Input
              fluid
              id="search"
              value={state.query}
              className="w-[322px] md:w-[588px]"
              onChange={(e, data) => setState({ ...state, query: data.value })}
              placeholder={i18n.ft(messages.allResources)}
              icon={
                isEmpty(state.query)
                  ? 'search'
                  : {
                      link: true,
                      name: 'times',
                      onClick: () => setState({ ...state, query: '' })
                    }
              }
            />
          </div>
          <div className="float-right">
            <Formik initialValues={{ sort: 'id' }} onSubmit={() => {}}>
              <SortDropdown handleSort={handleSort} />
            </Formik>
          </div>
        </div>
      </div>
      {state.query === '' ? (
        <>
          <div className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {learningResources?.length === 0 ? (
              <div className="mt-16 w-full mx-auto text-center">
                {i18n.ft(messages.noResults)}
              </div>
            ) : (
              learningResources?.map(
                (video: LearningResourceSearchResult, idx: number) => (
                  <VideoResource
                    ariaLabel={'Video Thumbnail'}
                    ariaRoleDescription={'Slide'}
                    handleClick={handleClick}
                    video={{
                      name: video.name,
                      id: video.id ? video.id : idx,
                      videoURI: video.video_uri
                        ? video.video_uri
                        : '1_bkuk1xi7',
                      estimatedTime: video.estimated_time
                        ? getHoursMinutes(video.estimated_time)
                        : '0'
                    }}
                    key={video.id}
                    tags={video.tags}
                    type={video.type}
                    showAllResources
                  />
                )
              )
            )}
          </div>
          <div className="flex justify-center mt-8">
            {isFetching ? (
              <Loader active inline="centered" />
            ) : (
              <button
                ref={loadMoreRef}
                className={`${
                  disableLoadMore ? 'bg-[#98d0e1]' : 'bg-[#0A9CCA]'
                } text-white font-bold py-2 px-4 rounded focus:shadow-outline`}
                onClick={loadMore}
                disabled={disableLoadMore}
              >
                {i18n.ft(messages.more)}
              </button>
            )}
          </div>
        </>
      ) : (
        <SearchResults
          query={state.query}
          direction={state.sort_dir as 'asc' | 'desc'}
          sortBy={state.sort_by}
        />
      )}
    </div>
  );
}

export default AllResourcesPage;

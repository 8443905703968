const PATH = 'fe.components.learning_resources.educator_header';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Learning Resources'
  },
  educatorView: {
    key: `${PATH}.educator_view`,
    defaultValue: 'You are in the educator view mode'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back to Coach View'
  }
};

export default messages;

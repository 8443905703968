import { useContext } from 'react';
import { NodeContext } from './NodeContext';

export function useNode() {
  const context = useContext(NodeContext);

  if (!context) {
    throw new Error('useNode must be used within a <NodeProvider />');
  }

  return context;
}

const PATH = 'fe.components.learning_resources.educator_resources_menu';

const messages = {
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'My Resources'
  },
  resourcesDescription: {
    key: `${PATH}.resources_description`,
    defaultValue: 'Based on my preferences'
  },
  recommendations: {
    key: `${PATH}.recommendations`,
    defaultValue: 'My Recommendations'
  },
  recommendationsDescription: {
    key: `${PATH}.recommendations_description`,
    defaultValue: 'Resources recommended to me'
  },
  started: {
    key: `${PATH}.started`,
    defaultValue: 'Getting Started with Learning Resources'
  },
  startedDescription: {
    key: `${PATH}.started_description`,
    defaultValue: 'Resources'
  },
  important: {
    key: `${PATH}.important`,
    defaultValue: 'Important Resources'
  },
  importantDescription: {
    key: `${PATH}.important_description`,
    defaultValue: 'Marked as important by me'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Based on My Classroom Scores'
  },
  classroomDescription: {
    key: `${PATH}.classroom_description`,
    defaultValue: 'Recommendations based on classroom observation scores'
  },
  history: {
    key: `${PATH}.history`,
    defaultValue: 'Based on My Viewing History'
  },
  historyDescription: {
    key: `${PATH}.history_description`,
    defaultValue: "Recommendations based on what I've seen"
  },
  help: {
    key: `${PATH}.help`,
    defaultValue: 'Dropdown shows different categories'
  },
  searchText: {
    key: `${PATH}.search_text`,
    defaultValue: 'Results for "{{query}}"'
  }
};

export default messages;

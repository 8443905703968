import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import {
  TrainingHistory,
  TrainingHistorySummary
} from 'types/api/TrainingHistory';

export function getTrainingHistoryByUserId(userId: number, params = {}) {
  return axios.get<TrainingHistory[]>(
    `/api/v2/users/${userId}/training_history`,
    { params }
  );
}

type TrainingHistoryData = {
  training_histories: TrainingHistory[];
  pagination: {
    total_pages: number;
    current_page: number;
    per_page: number;
    total_count: number;
  };
};

export function getLearningHistory(params = {}) {
  return axios.get<TrainingHistoryData>(`/api/v3/client/training_history`, {
    params
  });
}

export function useGetTrainingHistory(params = {}) {
  return useQuery({
    queryKey: ['client', 'training_history', params],
    queryFn: () => getLearningHistory(params).then(res => res.data)
  });
}

export function getTrainingHistorySummary(userId: number) {
  return axios.get<TrainingHistorySummary>(
    `/api/v2/users/${userId}/training_history/summary`
  );
}

export function useGetTrainingHistorySummary(userId: number) {
  return useQuery({
    queryKey: ['users', userId, 'training_history', 'summary'],
    queryFn: () => getTrainingHistorySummary(userId).then(res => res.data)
  });
}

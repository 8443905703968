import axios from 'axios';
import { MODE } from 'components/LearningResources/types';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE
} from 'pages/LearningResources/utils';

type Recommendations = {
  author_id?: number;
  recipient_id: any;
  resourceable_id: number;
  resourceable_type: string;
};

interface createRecommendationsProps {
  mode: MODE;
  message?: string;
  recommendations: Recommendations[];
}

export function createRecommendations(body: createRecommendationsProps) {
  return axios.post(`/api/v2/recommendations`, {
    ...body
  });
}

export function completedRecommended(
  educatorId: number | undefined,
  perPage: number = DEFAULT_PER_PAGE,
  currentPage: number = DEFAULT_CURRENT_PAGE
) {
  return axios.get(
    `/api/v2/learning_resources/watched_recommendations?educator_id=${educatorId}&per_page=${perPage}&page=${currentPage}`
  );
}

const PATH = 'fe.pages.measure.create';

const messages = {
  start: {
    key: `${PATH}.start`,
    defaultValue: 'Start Observation'
  },
  enter: {
    key: `${PATH}.enter`,
    defaultValue: 'Enter classroom information'
  },
  fields: {
    date: {
      key: `${PATH}.fields.date`,
      defaultValue: 'Observation Date'
    },
    datePlaceholder: {
      key: `${PATH}.fields.date_placeholder`,
      defaultValue: 'Select a date'
    },
    numberOfCycles: {
      key: `${PATH}.fields.number_of_cycles`,
      defaultValue: 'Number of Cycles'
    },
    numberOfCyclesPlaceholder: {
      key: `${PATH}.fields.number_of_cycles_placeholder`,
      defaultValue: 'Select a number'
    },
    center: {
      key: `${PATH}.fields.center`,
      defaultValue: 'Center'
    },
    centerPlaceholder: {
      key: `${PATH}.fields.center_placeholder`,
      defaultValue: 'Search for an option'
    },
    classroom: {
      key: `${PATH}.fields.classroom`,
      defaultValue: 'Classroom'
    },
    classroomPlaceholder: {
      key: `${PATH}.fields.classroom_placeholder`,
      defaultValue: 'Search for an option'
    },
    teachers: {
      key: `${PATH}.fields.teachers`,
      defaultValue: 'Teacher(s)'
    },
    teachersPlaceholder: {
      key: `${PATH}.fields.teachers_placeholder`,
      defaultValue: 'Separate names with commas'
    },
    classAssessment: {
      key: `${PATH}.fields.class_assessment`,
      defaultValue: 'CLASS Assessment'
    },
    language: {
      key: `${PATH}.fields.language`,
      defaultValue: 'Language'
    },
    jobID: {
      key: `${PATH}.fields.job_id`,
      defaultValue: 'Job ID'
    },
    jobPlaceholder: {
      key: `${PATH}.fields.job_id_placeholder`,
      defaultValue: 'Type the job identifier'
    },
    doubleCoding: {
      key: `${PATH}.fields.double_coding`,
      defaultValue: 'Double Coding Session'
    },
    primaryObserver: {
      key: `${PATH}.fields.primary_observer`,
      defaultValue: 'Primary Observer'
    },
    secondaryObserver: {
      key: `${PATH}.fields.secondary_observer`,
      defaultValue: 'Secondary Observer'
    },
    includesEnvironment: {
      key: `${PATH}.fields.includes_environment`,
      defaultValue: 'Include CLASS Environment'
    },
    gradeLevel: {
      key: `${PATH}.fields.grade_level`,
      defaultValue: 'Grade / Age Level'
    },
    gradeLevelPlaceholder: {
      key: `${PATH}.fields.grade_level_placeholder`,
      defaultValue: 'Select an option'
    },
    timeZone: {
      key: `${PATH}.fields.time_zone`,
      defaultValue: 'Observation Time Zone'
    },
    timeZonePlaceholder: {
      key: `${PATH}.fields.time_zone_placeholder`,
      defaultValue: 'Select a time zone'
    }
  },
  errors: {
    date: {
      required: {
        key: `${PATH}.errors.date.required`,
        defaultValue: 'Select the date the observation occurred.'
      }
    },
    fieldRequired: {
      key: `${PATH}.errors.field_required`,
      defaultValue: 'This field is required.'
    }
  },
  warnings: {
    date: {
      past: {
        key: `${PATH}.warnings.date.past`,
        defaultValue: 'Selected date is 1 month or more in the past.'
      }
    },
    teachers: {
      no_data: {
        key: `${PATH}.warnings.teachers.no_data`,
        defaultValue: 'No teachers entered.'
      }
    }
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  update: {
    key: `${PATH}.update`,
    defaultValue: 'Update Observation'
  },
  delete: {
    key: `${PATH}.delete`,
    defaultValue: 'Delete Observation'
  },
  modal: {
    delete: {
      key: `${PATH}.modal.delete`,
      defaultValue: 'Yes, Delete'
    },
    title: {
      key: `${PATH}.modal.title`,
      defaultValue: 'Delete Observation'
    },
    warning: {
      key: `${PATH}.modal.warning`,
      defaultValue: 'Are you sure you want to delete this observation?'
    }
  }
};

export default messages;

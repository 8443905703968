import i18n from 'helpers/i18n';
import messages from './messages';
import { Loader } from 'semantic-ui-react';
import starIcon from 'images/starIcon.svg';
import { useEffect, useState } from 'react';
import { getStarred } from 'actions/starred';
import starIconWhite from 'images/starIconWhite.svg';
import { toastError, toastSuccess } from 'components/Toast';
import { USER_ENGAGEMENT_EVENT_VERBS } from 'constants/userEngagementEventVerbs';
import {
  removeResourceAsImportant,
  createUserEngagementEvent
} from 'actions/userEngagementEvents';

interface MarkAsImportantProps {
  learningResourceID: number;
  userId: number;
}

function MarkAsImportant({ learningResourceID, userId }: MarkAsImportantProps) {
  const [starredId, setStarredId] = useState<number | null>(null);
  const [markingAsImportant, setMarkingAsImportant] = useState(false);

  useEffect(() => {
    if (!learningResourceID) {
      return;
    }
    getStarred(learningResourceID)
      .then(response => {
        setStarredId(response.data?.id ? response.data.id : null);
      })
      .catch(() => {});
  }, [learningResourceID]);

  function markAsImportant() {
    setMarkingAsImportant(true);

    if (starredId) {
      removeResourceAsImportant(starredId)
        .then(() => {
          toastSuccess({ description: i18n.ft(messages.important) });
          setStarredId(null);
        })
        .catch(() => {
          toastError({ description: i18n.ft(messages.failed) });
        })
        .finally(() => {
          setMarkingAsImportant(false);
        });
    } else {
      createUserEngagementEvent({
        objectId: learningResourceID,
        objectType: 'LearningResourceRecord',
        subjectId: userId,
        verb: USER_ENGAGEMENT_EVENT_VERBS.MARKS_AS_IMPORTANT
      })
        .then(response => {
          toastSuccess({ description: i18n.ft(messages.marked) });
          setStarredId(response.data?.id ? response.data.id : null);
        })
        .catch(() => {
          toastError({ description: i18n.ft(messages.failed) });
        })
        .finally(() => {
          setMarkingAsImportant(false);
        });
    }
  }

  return (
    <div className="flex">
      <button
        disabled={markingAsImportant}
        className={`mt-6 font-bold py-2 px-4 flex flex-wrap self-center rounded border border-[#6E2262] focus:shadow-outline ml-auto sm: mt-0 ${
          starredId ? 'bg-action text-white' : 'text-[#6E2262] bg-white'
        }`}
        onClick={() => markAsImportant()}
      >
        {markingAsImportant ? (
          <Loader active inline="centered" className="flex !w-32" size="tiny" />
        ) : (
          <>
            <img
              alt=""
              src={starredId ? starIconWhite : starIcon}
              className="flex"
            />{' '}
            <div className="self-center ml-2">
              {starredId ? i18n.ft(messages.unmark) : i18n.ft(messages.mark)}
            </div>
          </>
        )}
      </button>
    </div>
  );
}

export default MarkAsImportant;

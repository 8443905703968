import i18n from 'helpers/i18n';
import messages from './messages';
import { TextButton } from 'components/Organization/TextButton';
import { PrimaryButton } from 'components/Organization/PrimaryButton';

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop
} from '@headlessui/react';

interface DeleteNodeModalProps {
  /** Modal open state. */
  open: boolean;

  /** Callback fired when modal is closed. */
  onClose: () => void;

  /** Callback fired when deletion is confirmed. */
  onConfirm: () => void;

  /** Node type name: Center or Classroom */
  nodeTypeName: string;
}

export function DeleteNodeModal({
  open,
  onClose,
  onConfirm,
  nodeTypeName
}: DeleteNodeModalProps) {
  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose} className="text-black relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg bg-white p-4 rounded-lg">
          <DialogTitle className="font-sans font-bold text-xl m-0">
            {i18n.ft(messages.remove, { nodeTypeName }) + '?'}
          </DialogTitle>
          <p className="font-sans mt-2 mb-8">
            {i18n.ft(messages.description, {
              nodeTypeName: nodeTypeName.toLowerCase()
            })}
          </p>
          <div className="flex flex-col gap-3">
            <PrimaryButton onClick={handleConfirm}>
              {i18n.ft(messages.remove, { nodeTypeName })}
            </PrimaryButton>
            <TextButton onClick={handleCancel}>
              {i18n.ft(messages.cancel)}
            </TextButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

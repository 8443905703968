import { Fragment, useState } from 'react';
import clsx from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon, Loader } from 'semantic-ui-react';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';
import moment from 'moment';
import { getBackgroundColor } from '../AveragesByNodeTable/utils';
import { useGetReportAveragesByClassroom } from 'pages/Reports/Observation/utils';
import * as R from 'ramda';
import { ENV_SCALES_URL } from 'constants/routes';
import {
  ObservationReportType,
  DomainAverage,
  DimensionAverage
} from 'types/api/envScales/Reports';
import useFeatureFlags from 'hooks/useFeatureFlags';

const classes = {
  score: 'text-center border-2',
  header: 'py-8 border-2 border-b-4',
  nodeName: 'px-4 py-6 border-2',
  childName: 'pl-8 pr-4 py-6 border-2'
};

function sortClassroomByScore(
  classroom: ObservationReportType[],
  sortBy: string | null,
  sortDir: string | null,
  target: 'domain' | 'dimension'
) {
  if (!sortBy || !sortDir) {
    return classroom;
  }
  const findByDom = (score: DomainAverage) =>
    score.domain_abbreviation === sortBy;
  const findByDim = (score: DimensionAverage) =>
    score.dimension_abbreviation === sortBy;

  return R.sort((n1, n2) => {
    let cat1;
    let cat2;
    if (target === 'domain') {
      cat1 = n1.domain_averages.find(findByDom);
      cat2 = n2.domain_averages.find(findByDom);
    } else {
      cat1 = n1.dimension_averages.find(findByDim);
      cat2 = n2.dimension_averages.find(findByDim);
    }
    const value1 = Number(cat1?.avg) ?? 0;
    const value2 = Number(cat2?.avg) ?? 0;

    if (sortDir === 'asc') {
      return value1 - value2;
    } else if (sortDir === 'desc') {
      return value2 - value1;
    } else {
      return 0;
    }
  }, classroom);
}

interface AveragesByClassroomProps {
  assessmentTemplateId: number;
  nodeIds: number[];
  ageLevel: string;
  startDate: OptionalDate;
  endDate: OptionalDate;
  target: 'domain' | 'dimension';
  isFetchEnabled: boolean;
  isAllClassrooms: boolean;
}

interface AveragesByClassroomTableProps {
  /** API data. */
  props: AveragesByClassroomProps;

  /** Determines if we should use domains or dimensions. */
  target: 'domain' | 'dimension';
}

function AveragesByClassroomTable({
  props,
  target
}: AveragesByClassroomTableProps) {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<string | null>(null);
  const response = useGetReportAveragesByClassroom(props);
  const { isFeatureFlagEnabled } = useFeatureFlags();

  let targetAverage: 'domain_averages' | 'dimension_averages';

  if (!response.isSuccess) {
    return <Loader active inline="centered" />;
  }

  const headerList: string[] = [];
  if (target === 'domain') {
    targetAverage = 'domain_averages';
    const list = response.data.observations[0].domain_averages;
    list.forEach((item: DomainAverage) =>
      headerList.push(item.domain_abbreviation)
    );
  } else {
    targetAverage = 'dimension_averages';
    const list = response.data.observations[0].dimension_averages;
    list.forEach((item: DimensionAverage) =>
      headerList.push(item.dimension_abbreviation)
    );
  }

  const observations = sortClassroomByScore(
    response.data?.observations ?? [],
    sortBy,
    sortDir,
    target
  );

  function sortColumn(column: string) {
    if (sortBy !== column) {
      setSortBy(column);
      setSortDir('asc');
    } else if (sortDir === 'asc') {
      setSortBy(column);
      setSortDir('desc');
    } else {
      setSortBy(null);
      setSortDir(null);
    }
  }

  return (
    <div className="mt-20 pl-8 pr-4">
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className={clsx(classes.header, 'rounded-tl-2xl')}>
              {i18n.ft(messages.classroom)}
            </th>
            <th className={clsx(classes.header, 'rounded-tl-2xl')}>
              {i18n.ft(messages.date)}
            </th>

            {headerList.map((header, idx) => {
              return (
                <th
                  key={idx}
                  onClick={() => sortColumn(header)}
                  tabIndex={0}
                  className={clsx(classes.header, 'w-32 hover:cursor-pointer', {
                    'rounded-tr-2xl': idx === headerList.length - 1
                  })}
                >
                  <span>{header.toUpperCase()}</span>
                  <div className="inline-block">
                    {sortBy === header && sortDir === 'asc' ? (
                      <Icon color="grey" name="sort up" />
                    ) : null}
                    {sortBy === header && sortDir === 'desc' ? (
                      <Icon color="grey" name="sort down" />
                    ) : null}
                  </div>
                </th>
              );
            })}
            <th className={clsx(classes.header, 'rounded-tl-2xl')}>
              {i18n.ft(messages.download)}
            </th>
          </tr>
        </thead>
        <tbody>
          {observations.map((observation: ObservationReportType) => {
            const scores = observation[targetAverage];

            return (
              <Fragment key={observation.id}>
                <tr className="font-bold">
                  <td className={classes.nodeName}>
                    {observation.classroom_name}
                  </td>
                  <td className={classes.childName}>
                    {moment(observation.created_at).format('YYYY-MM-DD')}
                  </td>

                  {scores.map((score, idx) => {
                    const background = getBackgroundColor(
                      score.domain_abbreviation
                    );

                    return (
                      <td key={idx} className={clsx(classes.score, background)}>
                        {score.avg}
                      </td>
                    );
                  })}
                  <td className={classes.nodeName}>
                    <a
                      className="flex justify-center"
                      download
                      href={
                        isFeatureFlagEnabled('automated_feedback_v2')
                          ? `${ENV_SCALES_URL}/api/v1/assessments/${observation.assessment_id}.pdf?automated_feedback_v2=true`
                          : `${ENV_SCALES_URL}/api/v1/assessments/${observation.assessment_id}/pdf`
                      }
                    >
                      <Icon name="download" />
                    </a>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AveragesByClassroomTable;

import { createContext } from 'react';
import { AccountHierarchy } from 'types/api/AccountHierarchy';

type NodeContextValue = {
  accountId: number;
  node: AccountHierarchy;
  depth: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  as: React.ComponentType<any>;
};

export const NodeContext = createContext<NodeContextValue | null>(null);
NodeContext.displayName = 'NodeContext';
